import React from "react";
import { alpha, Box, Button, Dialog, Divider, Typography } from "@mui/material";
import { PreviewTag, TabKeyEnum, TagVisibilityEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils/timestamps";
import { TextView } from "../../components/TextView/TextView";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { ViewTagQuotes } from "./ViewTagQuotes";

interface Props {
  activeTab: TabKeyEnum;
  tag: PreviewTag | null;
  handleClose: () => void;
  handleEditTag: () => void;
  handlePublishTag: () => void;
  handleUnpublishTag: () => void;
  handleReviewTag: () => void;
}

interface TextViewInterface {
  label: string;
  value: string;
  visible?: boolean;
}

export const ViewTag: React.FC<Props> = props => {
  const { tag, handleClose, handleEditTag, handlePublishTag, activeTab, handleReviewTag, handleUnpublishTag } = props;

  const [openTagQuotes, setOpenTagQuotes] = React.useState<boolean>(false);

  const canApprovePublishTags = useHasPermission(UserPermissions.APPROVE_PUBLISH_TAGS);
  const canEditReadyAndUnPublishedTags = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS);
  const canUnPublishTags = useHasPermission(UserPermissions.UNPUBLISH_TAGS);

  if (!tag) {
    return (
      <Typography variant="h5" component="h2">
        No tag selected
      </Typography>
    );
  }

  const showPublishButton = activeTab === TabKeyEnum.ReadyForReview && canApprovePublishTags;
  const showEditButton = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedTags;
  const showReadyForReviewButton = activeTab === TabKeyEnum.Unpublished && canEditReadyAndUnPublishedTags;
  const showUnpublishButton = activeTab === TabKeyEnum.Published && canUnPublishTags;

  const textViews: TextViewInterface[] = [
    { label: "Tag", value: tag.tag },
    { label: "Type", value: tag?.type },
    { label: "Hide in mobile?", value: tag?.mobileHidden ? TagVisibilityEnum.Hidden : TagVisibilityEnum.Visible },
    { label: "Published", value: tag?.published },
    { label: "Created At", value: userFriendlyDateTimestamp(tag?.createdAt) },
    { label: "Created By", value: tag?.createdBy },
    { label: "Updated At", value: userFriendlyDateTimestamp(tag?.updatedAt) },
    { label: "Updated By", value: tag?.updatedBy },
    { label: "Deleted At", value: userFriendlyDateTimestamp(tag?.deletedAt), visible: !!tag?.deletedAt },
  ];

  const handleOpenTagQueries = () => {
    setOpenTagQuotes(true);
  };

  const handleCloseTagQuotes = () => {
    setOpenTagQuotes(false);
  };

  const alignButtonsEnd = !showPublishButton && !showEditButton && !showReadyForReviewButton && !showUnpublishButton;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: "100%" }}>
      <Dialog open={openTagQuotes} onClose={handleCloseTagQuotes} fullWidth maxWidth="lg">
        <ViewTagQuotes handleClose={handleCloseTagQuotes} tag={tag} />
      </Dialog>
      <Box>
        {textViews.map(textView =>
          textView.visible === undefined || textView.visible ? (
            <TextView key={textView.label} label={textView.label} value={textView.value} />
          ) : null,
        )}
        <Button onClick={handleOpenTagQueries} sx={{ p: 0, mt: 1 }}>
          <Box display="flex" alignItems="center" sx={{ p: 0 }}>
            <Typography color="primary" variant="body2">
              View All Quotes
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"} // If there are no buttons to show, align to the right for the cancel button
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {showPublishButton && (
            <Button
              onClick={handlePublishTag}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}
          {showReadyForReviewButton && (
            <Button
              onClick={handleReviewTag}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready for Review
            </Button>
          )}
          {showUnpublishButton && (
            <Button
              onClick={handleUnpublishTag}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Unpublish
            </Button>
          )}
          {showEditButton && (
            <Button
              onClick={handleEditTag}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
