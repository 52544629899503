import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.response.use(
  r => r,
  error => {
    if (error.response.status === 403 || error.response.status === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      Cookies.remove("accessToken", { path: "/" });
      Cookies.remove("refreshToken", { path: "/" });
      // window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default axios;
