import React from "react";
import { alpha, Box, Button, Divider, Typography } from "@mui/material";
import { ConfirmDialogEnum, ConfirmDialogType, Reference, TabKeyEnum, TabLabelEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils/timestamps";
import { TextView } from "../../components/TextView/TextView";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";

interface Props {
  reference: Reference | null;
  activeTab: TabKeyEnum;
  handleClose: () => void;
  handleEditReference: () => void;
  handleToggleConfirmDialog: (confirmDialog: ConfirmDialogType) => void;
}

interface TextViewInterface {
  label: string;
  value: string;
  visible?: boolean;
}

export const ViewReference: React.FC<Props> = props => {
  const { reference, handleClose, handleEditReference, handleToggleConfirmDialog, activeTab } = props;

  const canEditReferences = useHasPermission(UserPermissions.EDIT_REFERENCES);
  const canApprovePublishReferences = useHasPermission(UserPermissions.APPROVE_PUBLISH_REFERENCES);
  const canUnpublishReferences = useHasPermission(UserPermissions.UNPUBLISH_REFERENCES);

  if (!reference) {
    return (
      <Typography variant="h5" component="h2">
        No reference selected
      </Typography>
    );
  }

  const editButtonVisible = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReferences;
  const publishButtonVisible = activeTab === TabKeyEnum.ReadyForReview && canApprovePublishReferences;
  const reviewButtonVisible = activeTab === TabKeyEnum.Unpublished && canEditReferences;
  const unpublishButtonVisible = activeTab === TabKeyEnum.Published && canUnpublishReferences;

  const textViews: TextViewInterface[] = [
    { label: "Reference text", value: reference.text },
    {
      label: "Published",
      value:
        reference.published === 0
          ? TabLabelEnum.Unpublished
          : reference.published === -1
            ? TabLabelEnum.ReadyForReview
            : TabLabelEnum.Published,
    },
    { label: "Created At", value: userFriendlyDateTimestamp(reference?.createdAt) },
    { label: "Created By", value: reference?.createdBy?.name },
    { label: "Updated At", value: userFriendlyDateTimestamp(reference?.updatedAt) },
    { label: "Updated By", value: reference?.updatedBy?.name },
    { label: "Deleted At", value: userFriendlyDateTimestamp(reference?.deletedAt), visible: !!reference && !!reference?.deletedAt },
  ];

  const alignButtonsEnd = !publishButtonVisible && !reviewButtonVisible && !editButtonVisible && !unpublishButtonVisible;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: 400 }}>
      <Box>
        {textViews.map(textView =>
          textView.visible === undefined || textView.visible ? (
            <TextView key={textView.label} label={textView.label} value={textView.value} />
          ) : null,
        )}
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"} // If no buttons are visible, align to the right for cancel button
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {publishButtonVisible && (
            <Button
              onClick={() => handleToggleConfirmDialog(ConfirmDialogEnum.Publish)}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}
          {reviewButtonVisible && (
            <Button
              onClick={() => handleToggleConfirmDialog(ConfirmDialogEnum.Save)}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready for Review
            </Button>
          )}
          {unpublishButtonVisible && (
            <Button
              onClick={() => handleToggleConfirmDialog(ConfirmDialogEnum.Unpublish)}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Unpublish
            </Button>
          )}
          {editButtonVisible && (
            <Button
              onClick={handleEditReference}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
