import { jwtDecode } from "jwt-decode";

/**
 * Decodes a JWT token.
 *
 * @param {string} token - The JWT token to decode.
 * @returns {any | null} The decoded token or null if decoding fails.
 */
export const decodedToken = (token: string): any | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

/**
 * Appends new search parameters to an existing URLSearchParams object.
 *
 * @param {Record<string, any>} newParams - The new parameters to append.
 * @param {URLSearchParams} searchParams - The existing URLSearchParams object.
 * @returns {string} The updated search parameters as a string.
 */
export const appendSearchParams = (newParams: Record<string, any>, searchParams: URLSearchParams): string => {
  Object.entries(newParams).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  return searchParams.toString();
};

/**
 * Extracts the file name from a given path.
 *
 * @param {string} path - The path to extract the file name from.
 * @returns {string} The extracted file name.
 */
export const getNameFromPath = (path: string): string => {
  const pathSplitArray = path.split("/");
  return pathSplitArray[pathSplitArray.length - 1];
};

/**
 * Validates a password based on specific criteria.
 *
 * @param {string} password - The password to validate.
 * @returns {{ isValid: boolean; message: string }} An object containing the validation result and message.
 */
export const validatePassword = (password: string): { isValid: boolean; message: string } => {
  // Minimum 8 characters, at least 1 Alphabet uppercase or lowercase, 1 Number and 1 Special Character
  const strongRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const isValid = strongRegex.test(password);
  const message = isValid
    ? ""
    : "Password must be at least 8 characters long and contain at least 1 Alphabet, 1 Number and 1 Special Character";
  return { isValid, message };
};
