import { useState, useEffect } from "react";
import { alpha, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import { FileUpload } from "../../components";
import { getNameFromPath } from "../../utils";
import {
  IFileUploadHandle,
  IPreviewCulture,
  ModalType,
  SubmitButtonTextEnum,
  SubmitButtonEnum,
  SubmitButtonType,
  TabKeyEnum,
} from "../../utils/types";
import { useCreateCultureMutation, useEditCultureMutation } from "../../api/apiSlice";
import React from "react";
import toast from "react-hot-toast";
import { useDetectAnyChange, useDisableButtons } from "../../hooks";

type CreateOrEditCultureProps = {
  activeTab: TabKeyEnum;
  isModalOpen: boolean;
  handleClose: () => void;
  modalType: ModalType;
  culture: IPreviewCulture | null;
  fileUploadRef: React.RefObject<IFileUploadHandle>;
};

export const CreateOrEditCulture = (props: CreateOrEditCultureProps) => {
  const { handleClose, modalType, culture, fileUploadRef, activeTab } = props;
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [cultureData, setCultureData] = React.useState<Partial<IPreviewCulture> | null>(culture || null);
  const [buttonClicked, setButtonClicked] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [createCulture, createResult] = useCreateCultureMutation();
  const [editCulture, editResult] = useEditCultureMutation();
  const { isLoading: isLoadingCreate, isSuccess } = createResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editResult;

  const isLoading = isLoadingCreate || isEditLoading;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCultureData({ ...cultureData, [event.target.name]: event.target.value });
    if (event.target.value) {
      setError(null);
      return;
    }
    setError("Culture is required");
  };

  const getFileUploadError = (error: string | null) => {
    setFileUploadError(error);
  };

  const getUploadedFile = (file: File | null) => {
    setUploadedFile(file);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const buttonName = e.nativeEvent.submitter.name as SubmitButtonType;
    setButtonClicked(buttonName);
    if (!cultureData?.culture) {
      return;
    }

    if (!fileUploadRef.current) return;
    setIsUploadingImage(true);
    const uploadedFileName: string | null = await fileUploadRef.current.uploadFile();
    setIsUploadingImage(false);
    if (uploadedFileName === "error") return;

    const fileName = getNameFromPath(cultureData?.icon || "");

    // the update on the icon prop is not as the original: need to update icon
    const isNotPristine = fileName !== uploadedFileName;

    const published = buttonName === SubmitButtonEnum.SaveAsUnpublished ? 0 : -1;
    const culture = cultureData.culture.trim();
    switch (modalType) {
      case "Create":
        await createCulture({
          icon: uploadedFileName || "",
          culture,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Culture created successfully");
          })
          .catch(error => {
            toast.error(error.data || "Failed to create culture");
          });
        break;

      case "Edit":
        if (!cultureData.id || cultureData.published === undefined) break;

        await editCulture({
          id: cultureData.id,
          icon: isNotPristine ? uploadedFileName || "" : fileName || "",
          culture,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
        break;
    }
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  const hasChanges = useDetectAnyChange<Partial<IPreviewCulture> & { uploadedFile: File | null }>({
    initialObject: {
      culture: culture?.culture,
      uploadedFile: null,
    },
    object: {
      culture: cultureData?.culture,
      uploadedFile,
    },
  });

  const isEditing = modalType === "Edit";
  const hasRequiredFields = !!cultureData?.culture?.trim();

  const { disableReadyForReviewButton, disableSaveAsUnpublishedButton } = useDisableButtons({
    isLoading: isLoading || isUploadingImage,
    hasError: !!error || !!fileUploadError,
    hasRequiredFields,
    isEditing,
    hasChanges: hasChanges(),
    activeTab,
  });

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%", width: "500px" }}>
        <Box display="flex" flexDirection={"column"} gap={2}>
          <TextField
            required
            inputProps={{ maxLength: 255 }}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            label="Culture"
            name="culture"
            fullWidth
            value={cultureData?.culture || ""}
            error={!!error}
            helperText={error}
            size="small"
          />
          <FileUpload
            imgSrc={cultureData?.icon}
            ref={fileUploadRef}
            getFileUploadError={getFileUploadError}
            getUploadedFile={getUploadedFile}
          />
        </Box>

        <Box>
          <Divider />
          <Box display="flex" justifyContent="space-between" sx={{ py: 3, bottom: 0, gap: 2 }}>
            <Button
              disabled={disableReadyForReviewButton}
              sx={{ borderRadius: "20px" }}
              variant="contained"
              color="primary"
              type="submit"
              name={SubmitButtonEnum.ReadyForReview}
            >
              {(isLoading || isUploadingImage) && buttonClicked === SubmitButtonEnum.ReadyForReview && <CircularProgress size={20} />}
              {SubmitButtonTextEnum.ReadyForReview}
            </Button>
            <Button
              disabled={disableSaveAsUnpublishedButton}
              sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              variant="contained"
              color="primary"
              type="submit"
              name={SubmitButtonEnum.SaveAsUnpublished}
            >
              {(isLoading || isUploadingImage) && buttonClicked === SubmitButtonEnum.SaveAsUnpublished && <CircularProgress size={20} />}
              {SubmitButtonTextEnum.SaveAsUnpublished}
            </Button>
            <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
              <Typography sx={{ fontSize: "16px" }} color="textPrimary">
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
