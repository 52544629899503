// Permissions as defined in backend code: src/config/roles.ts
// export enum Permission {
//   CAN_MAN_USER = "CAN_MAN_USER",
//   CREATE_USER = "CREATE_USER",
//   UPDATE_USER = "UPDATE_USER",
//   DELETE_USER = "DELETE_USER",
//   VIEW_USER = "VIEW_USER",
//   CHANGE_USER_ROLE = "CHANGE_USER_ROLE",
//   APPROVE_CONTENT = "APPROVE_CONTENT",
//   REVOKE_CONTENT = "REVOKE_CONTENT",
//   CREATE_CONTENT_TRANSLATION = "CREATE_CONTENT_TRANSLATION",
//   UPDATE_CONTENT_TRANSLATION = "UPDATE_CONTENT_TRANSLATION",
//   DELETE_CONTENT_TRANSLATION = "DELETE_CONTENT_TRANSLATION",
//   VIEW_CONTENT_TRANSLATION = "VIEW_CONTENT_TRANSLATION",
//   CREATE_CONTENT = "CREATE_CONTENT",
//   UPDATE_CONTENT = "UPDATE_CONTENT",
//   DELETE_CONTENT = "DELETE_CONTENT",
//   VIEW_CONTENT = "VIEW_CONTENT",
//   ACCESS_MOBILE_APP = "ACCESS_MOBILE_APP",
//   CAN_SUBSCRIBE = "CAN_SUBSCRIBE",
// }

// Permissions as defined in https://docs.google.com/spreadsheets/d/12amWUTRRBIuaojAYSfKKfuDDLeoU6-zmSC0lFO6bbIA/edit?gid=1679949107#gid=1679949107
export enum UserPermissions {
  // Quotes
  VIEW_PUBLISHED_QUOTES = "VIEW_PUBLISHED_QUOTES",
  VIEW_UNPUBLISHED_QUOTES = "VIEW_UNPUBLISHED_QUOTES",
  VIEW_READY_TO_REVIEW_QUOTES = "VIEW_READY_TO_REVIEW_QUOTES",
  CREATE_QUOTES = "CREATE_QUOTES",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES",
  EDIT_PUBLISHED_QUOTES = "EDIT_PUBLISHED_QUOTES",
  APPROVE_PUBLISH_QUOTES = "APPROVE_PUBLISH_QUOTES",
  REMOVE_QUOTES = "REMOVE_QUOTES",
  UNPUBLISH_QUOTES = "UNPUBLISH_QUOTES",

  // Translations
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TRANSLATIONS = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TRANSLATIONS",
  EDIT_PUBLISHED_TRANSLATIONS = "EDIT_PUBLISHED_TRANSLATIONS",
  CREATE_TRANSLATIONS = "CREATE_TRANSLATIONS",
  APPROVE_PUBLISH_TRANSLATIONS = "APPROVE_PUBLISH_TRANSLATIONS",
  VIEW_TRANSLATIONS = "VIEW_TRANSLATIONS",
  REMOVE_TRANSLATIONS = "REMOVE_TRANSLATIONS",

  // User management
  VIEW_USERS_LIST = "VIEW_USERS_LIST",
  SEARCH_USERS = "SEARCH_USERS",
  ACTIVATE_INACTIVATE_USERS = "ACTIVATE_INACTIVATE_USERS",
  CREATE_USERS = "CREATE_USERS",
  EDIT_USERS = "EDIT_USERS",
  RESET_OWN_PASSWORD = "RESET_OWN_PASSWORD",
  RESET_ANY_USER_PASSWORD = "RESET_ANY_USER_PASSWORD",

  // Cultures
  VIEW_PUBLISHED_CULTURES = "VIEW_PUBLISHED_CULTURES",
  VIEW_UNPUBLISHED_CULTURES = "VIEW_UNPUBLISHED_CULTURES",
  VIEW_READY_TO_REVIEW_CULTURES = "VIEW_READY_TO_REVIEW_CULTURES",
  CREATE_CULTURES = "CREATE_CULTURES",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES",
  EDIT_PUBLISHED_CULTURES = "EDIT_PUBLISHED_CULTURES",
  APPROVE_PUBLISH_CULTURES = "APPROVE_PUBLISH_CULTURES",
  REMOVE_CULTURES = "REMOVE_CULTURES",
  UNPUBLISH_CULTURES = "UNPUBLISH_CULTURES",

  // Authors
  VIEW_PUBLISHED_AUTHORS = "VIEW_PUBLISHED_AUTHORS",
  VIEW_UNPUBLISHED_AUTHORS = "VIEW_UNPUBLISHED_AUTHORS",
  VIEW_READY_TO_REVIEW_AUTHORS = "VIEW_READY_TO_REVIEW_AUTHORS",
  CREATE_AUTHORS = "CREATE_AUTHORS",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS",
  EDIT_PUBLISHED_AUTHORS = "EDIT_PUBLISHED_AUTHORS",
  APPROVE_PUBLISH_AUTHORS = "APPROVE_PUBLISH_AUTHORS",
  REMOVE_AUTHORS = "REMOVE_AUTHORS",
  UNPUBLISH_AUTHORS = "UNPUBLISH_AUTHROS",

  // Sources
  VIEW_PUBLISHED_SOURCES = "VIEW_PUBLISHED_SOURCES",
  VIEW_UNPUBLISHED_SOURCES = "VIEW_UNPUBLISHED_SOURCES",
  VIEW_READY_TO_REVIEW_SOURCES = "VIEW_READY_TO_REVIEW_SOURCES",
  CREATE_SOURCES = "CREATE_SOURCES",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES",
  EDIT_PUBLISHED_SOURCES = "EDIT_PUBLISHED_SOURCES",
  APPROVE_PUBLISH_SOURCES = "APPROVE_PUBLISH_SOURCES",
  REMOVE_SOURCES = "REMOVE_SOURCES",
  UNPUBLISH_SOURCES = "UNPUBLISH_SOURCES",

  // Tags
  VIEW_PUBLISHED_TAGS = "VIEW_PUBLISHED_TAGS",
  VIEW_UNPUBLISHED_TAGS = "VIEW_UNPUBLISHED_TAGS",
  VIEW_READY_TO_REVIEW_TAGS = "VIEW_READY_TO_REVIEW_TAGS",
  CREATE_TAGS = "CREATE_TAGS",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS",
  EDIT_PUBLISHED_TAGS = "EDIT_PUBLISHED_TAGS",
  APPROVE_PUBLISH_TAGS = "APPROVE_PUBLISH_TAGS",
  REMOVE_TAGS = "REMOVE_TAGS",
  UNPUBLISH_TAGS = "UNPUBLISH_TAGS",

  // Languages
  VIEW_PUBLISHED_LANGUAGES = "VIEW_PUBLISHED_LANGUAGES",
  VIEW_UNPUBLISHED_LANGUAGES = "VIEW_UNPUBLISHED_LANGUAGES",
  VIEW_READY_TO_REVIEW_LANGUAGES = "VIEW_READY_TO_REVIEW_LANGUAGES",
  CREATE_LANGUAGES = "CREATE_LANGUAGES",
  EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES = "EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES",
  EDIT_PUBLISHED_LANGUAGES = "EDIT_PUBLISHED_LANGUAGES",
  APPROVE_PUBLISH_LANGUAGES = "APPROVE_PUBLISH_LANGUAGES",
  REMOVE_LANGUAGES = "REMOVE_LANGUAGES",
  UNPUBLISH_LANGUAGES = "UNPUBLISH_LANGUAGES",

  // References
  VIEW_PUBLISHED_REFERENCES = "VIEW_PUBLISHED_REFERENCES",
  CREATE_REFERENCES = "CREATE_REFERENCES",
  EDIT_REFERENCES = "EDIT_REFERENCES",
  APPROVE_PUBLISH_REFERENCES = "APPROVE_PUBLISH_REFERENCES",
  REMOVE_REFERENCES = "REMOVE_REFERENCES",
  UNPUBLISH_REFERENCES = "UNPUBLISH_REFERENCES",
}

export type Role = "Admin" | "Creator" | "Approver" | "Mobile User" | "Translator";

export interface RolePerms {
  [role: string]: UserPermissions[];
}

export const rolePerms: RolePerms = {
  Admin: [
    // Quotes
    UserPermissions.VIEW_PUBLISHED_QUOTES,
    UserPermissions.VIEW_UNPUBLISHED_QUOTES,
    UserPermissions.VIEW_READY_TO_REVIEW_QUOTES,
    UserPermissions.REMOVE_QUOTES,

    //Translations
    UserPermissions.VIEW_TRANSLATIONS,
    UserPermissions.REMOVE_TRANSLATIONS,

    // Users
    UserPermissions.VIEW_USERS_LIST,
    UserPermissions.SEARCH_USERS,
    UserPermissions.ACTIVATE_INACTIVATE_USERS,
    UserPermissions.CREATE_USERS,
    UserPermissions.EDIT_USERS,
    UserPermissions.RESET_OWN_PASSWORD,
    UserPermissions.RESET_ANY_USER_PASSWORD,

    // Culture
    UserPermissions.VIEW_PUBLISHED_CULTURES,
    UserPermissions.VIEW_UNPUBLISHED_CULTURES,
    UserPermissions.VIEW_READY_TO_REVIEW_CULTURES,
    UserPermissions.REMOVE_CULTURES,

    // Authors
    UserPermissions.VIEW_PUBLISHED_AUTHORS,
    UserPermissions.VIEW_UNPUBLISHED_AUTHORS,
    UserPermissions.VIEW_READY_TO_REVIEW_AUTHORS,
    UserPermissions.REMOVE_AUTHORS,

    // Sources
    UserPermissions.VIEW_PUBLISHED_SOURCES,
    UserPermissions.VIEW_UNPUBLISHED_SOURCES,
    UserPermissions.VIEW_READY_TO_REVIEW_SOURCES,
    UserPermissions.REMOVE_SOURCES,

    // Tags
    UserPermissions.VIEW_PUBLISHED_TAGS,
    UserPermissions.VIEW_UNPUBLISHED_TAGS,
    UserPermissions.VIEW_READY_TO_REVIEW_TAGS,
    UserPermissions.REMOVE_TAGS,

    // Languages
    UserPermissions.VIEW_PUBLISHED_LANGUAGES,
    UserPermissions.VIEW_UNPUBLISHED_LANGUAGES,
    UserPermissions.VIEW_READY_TO_REVIEW_LANGUAGES,
    UserPermissions.REMOVE_LANGUAGES,

    // References
    UserPermissions.VIEW_PUBLISHED_REFERENCES,
    UserPermissions.REMOVE_REFERENCES,
  ],
  Approver: [
    // Quotes
    UserPermissions.VIEW_PUBLISHED_QUOTES,
    UserPermissions.VIEW_UNPUBLISHED_QUOTES,
    UserPermissions.VIEW_READY_TO_REVIEW_QUOTES,
    UserPermissions.CREATE_QUOTES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES,
    UserPermissions.EDIT_PUBLISHED_QUOTES,
    UserPermissions.APPROVE_PUBLISH_QUOTES,
    UserPermissions.REMOVE_QUOTES,
    UserPermissions.UNPUBLISH_QUOTES,

    // Translations
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TRANSLATIONS,
    UserPermissions.EDIT_PUBLISHED_TRANSLATIONS,
    UserPermissions.CREATE_TRANSLATIONS,
    UserPermissions.APPROVE_PUBLISH_TRANSLATIONS,
    UserPermissions.VIEW_TRANSLATIONS,
    UserPermissions.REMOVE_TRANSLATIONS,

    // Users
    UserPermissions.RESET_OWN_PASSWORD,

    // Culture
    UserPermissions.VIEW_PUBLISHED_CULTURES,
    UserPermissions.VIEW_UNPUBLISHED_CULTURES,
    UserPermissions.VIEW_READY_TO_REVIEW_CULTURES,
    UserPermissions.CREATE_CULTURES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES,
    UserPermissions.EDIT_PUBLISHED_CULTURES,
    UserPermissions.APPROVE_PUBLISH_CULTURES,
    UserPermissions.REMOVE_CULTURES,
    UserPermissions.UNPUBLISH_CULTURES,

    // Authors
    UserPermissions.VIEW_PUBLISHED_AUTHORS,
    UserPermissions.VIEW_UNPUBLISHED_AUTHORS,
    UserPermissions.VIEW_READY_TO_REVIEW_AUTHORS,
    UserPermissions.CREATE_AUTHORS,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS,
    UserPermissions.EDIT_PUBLISHED_AUTHORS,
    UserPermissions.APPROVE_PUBLISH_AUTHORS,
    UserPermissions.REMOVE_AUTHORS,
    UserPermissions.UNPUBLISH_AUTHORS,

    // Sources
    UserPermissions.VIEW_PUBLISHED_SOURCES,
    UserPermissions.VIEW_UNPUBLISHED_SOURCES,
    UserPermissions.VIEW_READY_TO_REVIEW_SOURCES,
    UserPermissions.CREATE_SOURCES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES,
    UserPermissions.EDIT_PUBLISHED_SOURCES,
    UserPermissions.APPROVE_PUBLISH_SOURCES,
    UserPermissions.REMOVE_SOURCES,
    UserPermissions.UNPUBLISH_SOURCES,

    // Tags
    UserPermissions.VIEW_PUBLISHED_TAGS,
    UserPermissions.VIEW_UNPUBLISHED_TAGS,
    UserPermissions.VIEW_READY_TO_REVIEW_TAGS,
    UserPermissions.CREATE_TAGS,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS,
    UserPermissions.EDIT_PUBLISHED_TAGS,
    UserPermissions.APPROVE_PUBLISH_TAGS,
    UserPermissions.REMOVE_TAGS,
    UserPermissions.UNPUBLISH_TAGS,

    // Languages
    UserPermissions.VIEW_PUBLISHED_LANGUAGES,
    UserPermissions.VIEW_UNPUBLISHED_LANGUAGES,
    UserPermissions.VIEW_READY_TO_REVIEW_LANGUAGES,
    UserPermissions.CREATE_LANGUAGES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES,
    UserPermissions.EDIT_PUBLISHED_LANGUAGES,
    UserPermissions.APPROVE_PUBLISH_LANGUAGES,
    UserPermissions.REMOVE_LANGUAGES,
    UserPermissions.UNPUBLISH_LANGUAGES,

    // References
    UserPermissions.VIEW_PUBLISHED_REFERENCES,
    UserPermissions.CREATE_REFERENCES,
    UserPermissions.EDIT_REFERENCES,
    UserPermissions.APPROVE_PUBLISH_REFERENCES,
    UserPermissions.REMOVE_REFERENCES,
    UserPermissions.UNPUBLISH_REFERENCES,
  ],
  Creator: [
    // Quotes
    UserPermissions.VIEW_PUBLISHED_QUOTES,
    UserPermissions.VIEW_UNPUBLISHED_QUOTES,
    UserPermissions.VIEW_READY_TO_REVIEW_QUOTES,
    UserPermissions.CREATE_QUOTES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES,

    // Translations
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TRANSLATIONS,
    UserPermissions.CREATE_TRANSLATIONS,
    UserPermissions.VIEW_TRANSLATIONS,

    // Users
    UserPermissions.RESET_OWN_PASSWORD,

    // Culture
    UserPermissions.VIEW_PUBLISHED_CULTURES,
    UserPermissions.VIEW_UNPUBLISHED_CULTURES,
    UserPermissions.VIEW_READY_TO_REVIEW_CULTURES,
    UserPermissions.CREATE_CULTURES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES,

    // Authors
    UserPermissions.VIEW_PUBLISHED_AUTHORS,
    UserPermissions.VIEW_UNPUBLISHED_AUTHORS,
    UserPermissions.VIEW_READY_TO_REVIEW_AUTHORS,
    UserPermissions.CREATE_AUTHORS,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS,
    UserPermissions.APPROVE_PUBLISH_AUTHORS,

    // Sources
    UserPermissions.VIEW_PUBLISHED_SOURCES,
    UserPermissions.VIEW_UNPUBLISHED_SOURCES,
    UserPermissions.VIEW_READY_TO_REVIEW_SOURCES,
    UserPermissions.CREATE_SOURCES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES,
    UserPermissions.APPROVE_PUBLISH_SOURCES,

    // Tags
    UserPermissions.VIEW_PUBLISHED_TAGS,
    UserPermissions.VIEW_UNPUBLISHED_TAGS,
    UserPermissions.VIEW_READY_TO_REVIEW_TAGS,
    UserPermissions.CREATE_TAGS,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS,

    // Languages
    UserPermissions.VIEW_PUBLISHED_LANGUAGES,
    UserPermissions.VIEW_UNPUBLISHED_LANGUAGES,
    UserPermissions.VIEW_READY_TO_REVIEW_LANGUAGES,
    UserPermissions.CREATE_LANGUAGES,
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES,

    // References
    UserPermissions.VIEW_PUBLISHED_REFERENCES,
    UserPermissions.CREATE_REFERENCES,
    UserPermissions.EDIT_REFERENCES,
    UserPermissions.APPROVE_PUBLISH_REFERENCES,
  ],
  Translator: [
    // Quotes
    UserPermissions.VIEW_PUBLISHED_QUOTES,
    UserPermissions.VIEW_UNPUBLISHED_QUOTES,
    UserPermissions.VIEW_READY_TO_REVIEW_QUOTES,

    // Translations
    UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TRANSLATIONS,
    UserPermissions.CREATE_TRANSLATIONS,
    UserPermissions.VIEW_TRANSLATIONS,

    // Users
    UserPermissions.RESET_OWN_PASSWORD,

    // Culture
    UserPermissions.VIEW_PUBLISHED_CULTURES,
    UserPermissions.VIEW_UNPUBLISHED_CULTURES,
    UserPermissions.VIEW_READY_TO_REVIEW_CULTURES,

    // Authors
    UserPermissions.VIEW_PUBLISHED_AUTHORS,
    UserPermissions.VIEW_UNPUBLISHED_AUTHORS,
    UserPermissions.VIEW_READY_TO_REVIEW_AUTHORS,

    // Sources
    UserPermissions.VIEW_PUBLISHED_SOURCES,
    UserPermissions.VIEW_UNPUBLISHED_SOURCES,
    UserPermissions.VIEW_READY_TO_REVIEW_SOURCES,

    // Tags
    UserPermissions.VIEW_PUBLISHED_TAGS,
    UserPermissions.VIEW_UNPUBLISHED_TAGS,
    UserPermissions.VIEW_READY_TO_REVIEW_TAGS,

    // Languages
    UserPermissions.VIEW_PUBLISHED_LANGUAGES,
    UserPermissions.VIEW_UNPUBLISHED_LANGUAGES,
    UserPermissions.VIEW_READY_TO_REVIEW_LANGUAGES,

    // References
    UserPermissions.VIEW_PUBLISHED_REFERENCES,
  ],

  "Mobile User": [
    // Quotes
    UserPermissions.VIEW_PUBLISHED_QUOTES,

    // Translations
    UserPermissions.VIEW_TRANSLATIONS,

    // Users
    UserPermissions.RESET_OWN_PASSWORD,

    // Culture
    UserPermissions.VIEW_PUBLISHED_CULTURES,

    //Authors
    UserPermissions.VIEW_PUBLISHED_AUTHORS,

    // Sources
    UserPermissions.VIEW_PUBLISHED_SOURCES,

    // Tags
    UserPermissions.VIEW_PUBLISHED_TAGS,

    // Languages
    UserPermissions.VIEW_PUBLISHED_LANGUAGES,

    // References
    UserPermissions.VIEW_PUBLISHED_REFERENCES,
  ],
};

/**
 * Check if a user has access to a specific permission based on their roles.
 * @param {Role[]} userRoles - The roles assigned to the user.
 * @param {UserPermissions} requiredPermission - The permission to check.
 * @returns {boolean} True if the user has access, false otherwise.
 */
export const canAccess = (userRoles: Role[], requiredPermission: UserPermissions): boolean => {
  return userRoles.some(role => rolePerms[role].includes(requiredPermission));
};
