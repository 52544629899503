import { userFriendlyDateTimestamp } from "../../utils/timestamps";
import { MenuItem } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { MouseEvent, useMemo, useState, useCallback } from "react";
import { TabKeyEnum, TableMenuItemInterface } from "../../utils/types";
import { OneLiner } from "../../components/Table/Table";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handlePreviewReference: () => void;
  handleEditReference: () => void;
  handleUnpublishReference: () => void;
  handlePublishReference: () => void;
  handleRemoveReference: () => void;
}
export const useGetReferencesTableColumns = (params: Params) => {
  const {
    setHoveredRow,
    hoveredRow,
    activeTab,
    handlePreviewReference,
    handleEditReference,
    handleUnpublishReference,
    handlePublishReference,
    handleRemoveReference,
  } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewReferenceSelection = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePreviewReference();
    },
    [handlePreviewReference],
  );

  const handleEditSelectedReference = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditReference();
    },
    [handleEditReference],
  );

  const handleUnpublishSelectedReference = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishReference();
    },
    [handleUnpublishReference],
  );

  const handlePublishSelectedReference = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishReference();
    },
    [handlePublishReference],
  );

  const handleRemoveSelectedReference = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveReference();
    },
    [handleRemoveReference],
  );

  const canEditReferences = useHasPermission(UserPermissions.EDIT_REFERENCES);
  const canApprovePublishReferences = useHasPermission(UserPermissions.APPROVE_PUBLISH_REFERENCES);
  const canRemoveReferences = useHasPermission(UserPermissions.REMOVE_REFERENCES);
  const canUnpublishReferences = useHasPermission(UserPermissions.UNPUBLISH_REFERENCES);

  const columns = useMemo(() => {
    const menuItems: TableMenuItemInterface[] = [
      {
        label: "Approve & Publish",
        onClick: handlePublishSelectedReference,
        visible: activeTab === TabKeyEnum.ReadyForReview && canApprovePublishReferences,
      },
      {
        label: "Preview",
        onClick: handlePreviewReferenceSelection,
        visible: true,
      },
      {
        label: "Edit",
        onClick: handleEditSelectedReference,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReferences,
      },
      {
        label: "Unpublish",
        onClick: handleUnpublishSelectedReference,
        visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishReferences,
      },
      {
        label: "Remove",
        onClick: handleRemoveSelectedReference,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveReferences,
        sx: { color: "red" },
      },
    ];

    return [
      { accessorKey: "id", header: "ID", size: 150, Cell: ({ row }: any) => <OneLiner text={row.original.id} /> },
      {
        accessorKey: "text",
        header: "Text",
        size: 600,
        Cell: ({ row }: any) => <OneLiner text={row.original.text} />,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.createdAt)} />,
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        Cell: ({ row }: any) => <OneLiner text={row.original.createdBy?.name} />,
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.updatedAt)} />,
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        Cell: ({ row }: any) => <OneLiner text={row.original.updatedBy?.name} />,
      },
      {
        accessorKey: "action",
        id: "action",
        size: 50,
        header: "",
        enableResizing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            visibility: "hidden",
          },
        }),
        Cell: ({ row }: any) => {
          if (hoveredRow !== row.index) return null;
          return (
            <ActionCell
              shouldCloseMenuSelection={shouldCloseMenuSelection}
              setShouldCloseMenuSelection={setShouldCloseMenuSelection}
              hovered={hoveredRow === row.index}
              loading={false}
              activeTab={activeTab}
              onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation?.();
                setHoveredRow(row.index);
              }}
            >
              {menuItems.map(
                (item, index) =>
                  item.visible && (
                    <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  ),
              )}
            </ActionCell>
          );
        },
      },
    ];
  }, [
    activeTab,
    canApprovePublishReferences,
    canEditReferences,
    canRemoveReferences,
    canUnpublishReferences,
    handleEditSelectedReference,
    handlePreviewReferenceSelection,
    handlePublishSelectedReference,
    handleRemoveSelectedReference,
    handleUnpublishSelectedReference,
    hoveredRow,
    setHoveredRow,
    shouldCloseMenuSelection,
  ]);

  return columns;
};
