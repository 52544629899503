import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";

// IP Geolocation API
export const ipApi = createApi({
  reducerPath: "ipApi",
  baseQuery: axiosBaseQuery({ baseUrl: "https://ipapi.co" }),
  endpoints: builder => ({
    getIpJson: builder.query({
      query: () => ({
        url: "/json",
        method: "get",
      }),
    }),
  }),
});

export const { useGetIpJsonQuery } = ipApi;
