import { Paper, alpha, Box, IconButton, Typography, Chip, Divider, Button, CircularProgress, List, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { TabKeyEnum, TabLabelEnum } from "../../utils/types";

interface Props {
  loading: boolean;
  activeTab: TabKeyEnum;
  values: any;
  handleClose: () => void;
  title: string;
  onEdit: (v: any) => void;
  onPublish: (v: any) => void;
  onUnpublish: (v: any) => void;
  onReadyForReview: (v: any) => void;
}

interface TextViewProps {
  label: string;
  value?: string;
}

const TextView = ({ label, value }: TextViewProps) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography sx={theme => ({ color: theme.palette.grey["500"], mb: 1 })} variant="caption">
        {label}
      </Typography>
      <Typography>{value || "-"}</Typography>
    </Box>
  );
};

export const ViewQuote = (props: Props): JSX.Element => {
  const { values: v, loading, handleClose, onEdit, onPublish, onReadyForReview, onUnpublish, activeTab } = props;

  const canApprovePublishQuotes = useHasPermission(UserPermissions.APPROVE_PUBLISH_QUOTES);
  const canEditReadyAndUnPublishedQuotes = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES);
  const canUnpublishQuotes = useHasPermission(UserPermissions.UNPUBLISH_QUOTES);

  const showApprovePublishButton = activeTab === TabKeyEnum.ReadyForReview && canApprovePublishQuotes;
  const showReadyForReviewButton = activeTab === TabKeyEnum.Unpublished && canEditReadyAndUnPublishedQuotes;
  const showUnpublishButton = activeTab === TabKeyEnum.Published && canUnpublishQuotes;
  const showEditButton = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedQuotes;

  const justifyButtonsEnd = !showApprovePublishButton && !showReadyForReviewButton && !showUnpublishButton && !showEditButton;

  const values = v || {};
  const published =
    values?.published === 0 ? TabLabelEnum.Unpublished : values.published === -1 ? TabLabelEnum.ReadyForReview : TabLabelEnum.Published;

  return (
    <Paper
      sx={theme => ({
        background: alpha(theme.palette.background.default, 1),
        width: "100%",
        height: "100%",
        display: "flex",
        minWidth: 500,
        maxWidth: 500,
        flexDirection: "column",
        overflowY: "auto",
      })}
    >
      <Box display="flex" justify-content="space-between" alignItems="center" sx={{ padding: "24px 24px 0", width: "100%" }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          {props.title}
        </Typography>
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box flex={1} sx={{ p: 3 }}>
        <TextView label="Quote" value={values.engText} />
        <TextView label="Published" value={published} />
        <TextView label="Original Language Text:" value={values.origText} />
        <TextView label="Original Language:" value={values.origLang} />
        <TextView label="Original Culture:" value={values.cultures?.map(({ culture }: any) => culture).join(",")} />
        <TextView label="Source:" value={values.sources?.map(({ name }: any) => name).join(",")} />
        <TextView label="Author:" value={values.authors?.map(({ name }: any) => name).join(",")} />
        <TextView label="Origin Time Period:" value={values.origTimePeriod} />
        <Typography sx={{ fontWeight: 600 }}>Tags</Typography>
        <Box sx={{ my: 2 }}>
          <Typography sx={theme => ({ color: theme.palette.grey["500"], mb: 1 })} variant="caption">
            Mobile Users Visible:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {values?.tags?.map((item: any) => {
              if (!item.mobileHidden) {
                return <Chip sx={{ borderRadius: 1 }} key={item.id} label={item.tag} variant="outlined" />;
              }
              return null;
            })}
          </Box>
        </Box>
        <Box>
          <Typography sx={theme => ({ color: theme.palette.grey["500"], mb: 1 })} variant="caption">
            Admin Only:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {values?.tags?.map((item: any) => {
              if (item.mobileHidden) {
                return <Chip sx={{ borderRadius: 1 }} key={item.id} label={item.tag} variant="outlined" />;
              }
              return null;
            })}
          </Box>
        </Box>
        <Typography sx={{ fontWeight: 600, my: 2 }}>Translations:</Typography>
        {values.trans?.map(({ lang, text, id }: any) => <TextView key={id} label={lang} value={text} />)}
        <List sx={{ width: "100%" }} subheader={<Typography sx={{ fontWeight: 600, mt: 2 }}>Reference:</Typography>}>
          {values.refs?.map(({ text, id }: any) => (
            <>
              <ListItemText key={id} secondary={text} />
              <Divider component="li" />
            </>
          ))}
        </List>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent={justifyButtonsEnd ? "flex-end" : "space-between"}
        sx={{ py: 3, px: 2, position: "relative", bottom: 0, gap: 2 }}
      >
        {showApprovePublishButton && (
          <Button
            onClick={() => onPublish(values)}
            disabled={loading}
            sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {loading ? <CircularProgress size={16} /> : "Approve & Publish"}
          </Button>
        )}
        {showReadyForReviewButton && (
          <Button
            onClick={() => onReadyForReview(values)}
            sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disabled={loading}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {loading ? <CircularProgress size={16} /> : "Ready for Review"}
          </Button>
        )}
        {showUnpublishButton && (
          <Button
            onClick={() => onUnpublish(values)}
            disabled={loading}
            sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {loading ? <CircularProgress size={16} /> : "Unpublish"}
          </Button>
        )}
        {showEditButton && (
          <Button
            onClick={() => onEdit(values)}
            disabled={loading}
            disableElevation
            color="primary"
            sx={{ flex: 1, borderRadius: "20px" }}
            variant="contained"
            type="button"
          >
            {loading ? <CircularProgress size={16} /> : ""} Edit
          </Button>
        )}
        <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
          <Typography sx={{ fontSize: "16px" }} color="textPrimary">
            Cancel
          </Typography>
        </Button>
      </Box>
    </Paper>
  );
};
