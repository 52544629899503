import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useGetIpJsonQuery } from "../../api/thirdPartyApiSlice";
import { useLoginMutation } from "../../api/apiSlice";
import { useAuth } from "../../hooks";
import { getCachedIpInfo, setCachedIpInfo } from "../../utils/ipInfoCache";

interface RegisterState {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { setLocalStorage, removeLocalStorage } = useAuth();

  const navigate = useNavigate();

  const [loginData, setLoginData] = useState<RegisterState>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<RegisterState>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  // Retrieve cached IP data if available
  const cachedIpData = getCachedIpInfo();
  const shouldFetchIpData = !cachedIpData;

  // NOTE: The ipData could be undefined if the request to fetch IP information fails.
  // This scenario is handled in the api slice method to ensure that the application
  // does not break if ipData is not available. The login mutation in the api slice
  // checks for the presence of ipData before attempting to access its properties.
  const { data: ipData } = useGetIpJsonQuery({}, { skip: !shouldFetchIpData });

  const [login, loginResult] = useLoginMutation();

  const { isSuccess, isLoading } = loginResult;

  useEffect(() => {
    // Cache the IP data if it is fetched
    if (ipData) {
      setCachedIpInfo(ipData);
    }
  }, [ipData, cachedIpData]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    login({ email: loginData.email, password: loginData.password, ipData: ipData || cachedIpData })
      .unwrap()
      .then(data => {
        setLocalStorage(data);
      })
      .catch(() => {
        setErrors({
          email: "Invalid email or password",
          password: "Invalid email or password",
        });
      });
  };

  useEffect(() => {
    if (isSuccess && loginResult?.data?.accessToken) {
      navigate("/quotes", { replace: true });
    }
  }, [isSuccess, loginResult, navigate]);

  useEffect(() => {
    removeLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <Box sx={{ pb: 4 }}>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Login
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField
          label="Email"
          variant="standard"
          name="email"
          value={loginData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="standard"
          name="password"
          error={!!errors.password}
          helperText={errors.password}
          value={loginData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button
          sx={{ mt: 8, width: "100%", textTransform: "none" }}
          disableElevation
          disabled={isLoading}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >
          {isLoading ? <CircularProgress size={30} /> : `Sign In`}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
