import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { Table } from "../../components";
import { useMemo, useState } from "react";
import { IQuote, PreviewTag, TabLabelEnum } from "../../utils/types";
import { useGetTagQuotesQuery } from "../../api/apiSlice";
import { OneLiner } from "../../components/Table/Table";
import { MRT_ColumnDef } from "material-react-table";
import React from "react";

interface Props {
  tag: PreviewTag;
  handleClose: () => void;
}

export const ViewTagQuotes: React.FC<Props> = props => {
  const { tag, handleClose } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: rowData,
    isLoading,
    isFetching,
  } = useGetTagQuotesQuery({
    id: tag.id,
    per_page: pagination.pageSize,
    page: pagination.pageIndex + 1,
  });

  const quotesData = React.useMemo<MRT_ColumnDef<any>[]>(
    () =>
      rowData?.data?.map((quote: IQuote) => ({
        ...quote,
        originCulture: quote?.cultures?.[0]?.culture,
        author: quote.authors.map(({ name }) => name).join(", "),
        source: quote.sources.map(({ name }) => name).join(", "),
      })) ?? [],
    [rowData?.data],
  );

  const columns = useMemo(() => {
    return [
      { accessorKey: "engText", header: "Quote", size: 200, Cell: ({ row }: any) => <OneLiner text={row.original.engText} /> },
      {
        accessorKey: "published",
        header: "Published",
        Cell: ({ row }: any) =>
          row.original.published === 0
            ? TabLabelEnum.Unpublished
            : row.original.published === -1
              ? TabLabelEnum.ReadyForReview
              : TabLabelEnum.Published,
      },
      { accessorKey: "origLang", header: "Origin Language" },
      { accessorKey: "originCulture", header: "Origin Culture" },
      {
        accessorKey: "source",
        header: "Source",
      },
      {
        accessorKey: "author",
        header: "Author",
      },
      {
        accessorKey: "origTimePeriod",
        header: "Origin Time Period",
      },
    ];
  }, []);

  return (
    <Box>
      <DialogTitle>
        <Typography variant="h6">Quotes tagged with "{tag.tag}"</Typography>
      </DialogTitle>
      <DialogContent>
        {!isLoading && !isFetching && rowData?.total === 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <DialogContentText id="alert-dialog-description">No quotes found for this tag</DialogContentText>
          </Box>
        ) : (
          <Table
            enableStickyHeader
            columns={columns}
            data={quotesData}
            manualPagination
            onPaginationChange={setPagination}
            state={{ pagination }}
            rowCount={rowData?.total}
            loading={isLoading || isFetching}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disableElevation variant="outlined" sx={{ width: 100, borderRadius: "20px" }} onClick={handleClose} type="button">
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
};
