import { Autocomplete, Box, Button, CircularProgress, Divider, TextField, Typography, alpha } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  PreviewTag,
  SubmitButtonEnum,
  SubmitButtonTextEnum,
  SubmitButtonType,
  TabKeyEnum,
  TagTypeEnum,
  TagVisibilityEnum,
} from "../../utils/types";
import { useCreateTagMutation, useEditTagMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { useDetectChanges, useDisableButtons } from "../../hooks";

interface CreateOrEditTagProps {
  activeTab: TabKeyEnum;
  tag?: PreviewTag | null;
  handleClose: () => void;
  isEditing?: boolean;
  isDrawerOpen?: boolean;
}

interface Option {
  title: string;
  value: boolean | string;
}

const mobileHiddenOptions: Option[] = [
  { title: TagVisibilityEnum.Hidden, value: true },
  { title: TagVisibilityEnum.Visible, value: false },
];

const tagTypeOptions: Option[] = [
  { title: TagTypeEnum.Category, value: TagTypeEnum.Category },
  { title: TagTypeEnum.MainCategory, value: TagTypeEnum.MainCategory },
];

export const CreateOrEditTag: FC<CreateOrEditTagProps> = props => {
  const { tag, handleClose, isEditing, activeTab } = props;

  const [tagDetails, setTagDetails] = React.useState<Partial<PreviewTag> | null>(tag || null);
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTagDetails({ ...tagDetails, [event.target.id]: event.target.value });

    // Only show error for required fields
    if (event.target.id === "tag") {
      if (event.target.value) {
        setError(null);
        return;
      }
      setError("Tag is required");
    }
  };

  const [createTag, createTagResult] = useCreateTagMutation();
  const [editTag, editTagResult] = useEditTagMutation();
  const { isLoading: isLoadingAdd, isSuccess } = createTagResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editTagResult;

  const isLoading = isLoadingAdd || isEditLoading;

  const handleSubmit = (published: -1 | 1 | 0, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);
    if (tagDetails?.tag && tagDetails?.type) {
      const tag = tagDetails.tag.trim();
      const type = tagDetails.type.trim();
      if (isEditing && tagDetails?.id) {
        editTag({
          id: tagDetails.id,
          tag,
          type,
          mobileHidden: tagDetails.mobileHidden,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
      } else {
        createTag({
          tag,
          type,
          mobileHidden: tagDetails.mobileHidden,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Tag added successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to create tag");
          });
      }
    }
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  const mobileHiddenDefaultOptions = {
    options: mobileHiddenOptions,
    getOptionLabel: (option: Option) => option.title,
  };

  const tagTypeDefaultOptions = {
    options: tagTypeOptions,
    getOptionLabel: (option: Option) => option.title,
  };

  const handleAutocompleteChange = (event: any, newValue: Option | null) => {
    setTagDetails({ ...tagDetails, mobileHidden: newValue?.value as boolean });
  };

  const handleTagTypeChange = (event: any, newValue: Option | null) => {
    setTagDetails({ ...tagDetails, type: newValue?.value as string });
  };

  useEffect(() => {
    // set default values for properties that are not present in the tag object
    const defaultTagDetails = {
      type: tagDetails?.type ?? (tagTypeOptions[0].value as string),
      mobileHidden: tagDetails?.mobileHidden === undefined ? (mobileHiddenOptions[1].value as boolean) : tagDetails.mobileHidden,
    };
    setTagDetails({ ...tagDetails, ...defaultTagDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasChanges = useDetectChanges<Partial<PreviewTag>>({ initialObject: tag, object: tagDetails });
  const hasRequiredFields = tagDetails?.tag?.trim() && tagDetails?.type?.trim() && tagDetails?.mobileHidden !== undefined ? true : false;

  const { disableReadyForReviewButton, disableSaveAsUnpublishedButton } = useDisableButtons({
    isLoading,
    hasRequiredFields,
    isEditing: isEditing || false,
    hasChanges: hasChanges(),
    activeTab,
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box gap={5} display="flex" flexDirection={"column"}>
        <TextField
          label="Tag"
          id="tag"
          value={tagDetails?.tag || ""}
          error={!!error}
          helperText={error}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          fullWidth
          required
          margin="normal"
        />
        <Autocomplete
          {...tagTypeDefaultOptions}
          id="type"
          disableClearable
          value={tagDetails?.type === TagTypeEnum.MainCategory ? tagTypeOptions[1] : tagTypeOptions[0]}
          onChange={handleTagTypeChange}
          renderInput={params => <TextField {...params} label="Tag Type" variant="standard" />}
        />
        <Autocomplete
          {...mobileHiddenDefaultOptions}
          id="mobileHidden"
          disableClearable
          value={tagDetails?.mobileHidden === true ? mobileHiddenOptions[0] : mobileHiddenOptions[1]}
          onChange={handleAutocompleteChange}
          sx={{ paddingTop: "16px" }}
          renderInput={params => <TextField {...params} label="Hide from users" variant="standard" />}
        />
      </Box>
      <Box>
        <Divider />

        <Box display="flex" justifyContent="flex-end" sx={{ p: 3, position: "relative", bottom: 0, gap: 2 }}>
          <Button
            onClick={() => handleSubmit(-1, SubmitButtonEnum.ReadyForReview)}
            disabled={disableReadyForReviewButton}
            sx={{ borderRadius: "20px" }}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
            name={SubmitButtonEnum.ReadyForReview}
          >
            {isLoading && clickedButton === SubmitButtonEnum.ReadyForReview ? <CircularProgress size={16} /> : ""}
            {SubmitButtonTextEnum.ReadyForReview}
          </Button>
          <Button
            onClick={() => handleSubmit(0, SubmitButtonEnum.SaveAsUnpublished)}
            disabled={disableSaveAsUnpublishedButton}
            disableElevation
            color="primary"
            sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            variant="contained"
            type="button"
            name={SubmitButtonEnum.SaveAsUnpublished}
          >
            {isLoading && clickedButton === SubmitButtonEnum.SaveAsUnpublished ? <CircularProgress size={16} /> : ""}
            {SubmitButtonTextEnum.SaveAsUnpublished}
          </Button>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
