import { TabKeyEnum } from "../utils/types";

interface UseDisableButtonsParams {
  isLoading: boolean;
  hasRequiredFields: boolean;
  hasError?: boolean;
  isEditing: boolean;
  hasChanges: boolean;
  activeTab: TabKeyEnum;
}

interface DisableButtons {
  disableReadyForReviewButton: boolean;
  disableSaveAsUnpublishedButton: boolean;
}

// This hook is used  to disable the submit buttons based on the current state of the form.
// The submit buttons are disabled when the form is loading, when there are missing required fields,
// when there is an error, when the form is not in edit mode and there are no changes,
// and when the form is in edit mode and there are no changes in the current active tab.

/**
 * useDisableButtons hook
 * @param {Object} params - The parameters of the hook.
 * @param {boolean} params.isLoading - Indicates whether the form is currently loading.
 * @param {boolean} params.hasRequiredFields - Indicates whether the form has all required fields.
 * @param {boolean} [params.hasError] - Indicates whether there is an error in the form (optional).
 * @param {boolean} params.isEditting - Indicates whether the form is in edit mode.
 * @param {boolean} params.hasChanges = Indicates whether there are changesin the form.
 * @param {TabKeyEnum} params.activeTab = Reperesents the currently active page tab.
 *
 * @returns {Object} - An object containing the disabled state of the "Ready for Review" and "Save as Unpublsihed" buttons.
 * @returns {boolean} disableReadyForReviewButton - Determines whether the "Ready for Review" button should be disabled.
 * @returns {boolean} disablSaveAsUnpublishedButton - Determines whether the "Save as Unpublished" button should be disabled.
 */
export const useDisableButtons = ({
  isLoading,
  hasRequiredFields,
  isEditing,
  hasChanges,
  activeTab,
  hasError,
}: UseDisableButtonsParams): DisableButtons => {
  const shouldDisableSubmitButton = isLoading || !hasRequiredFields || hasError;
  const shouldDisableButtonOnChanges = isEditing && !hasChanges;

  const disableReadyForReviewButton =
    shouldDisableSubmitButton || (activeTab === TabKeyEnum.ReadyForReview && shouldDisableButtonOnChanges);
  const disableSaveAsUnpublishedButton =
    shouldDisableSubmitButton || (activeTab === TabKeyEnum.Unpublished && shouldDisableButtonOnChanges);

  return { disableReadyForReviewButton, disableSaveAsUnpublishedButton };
};
