import { Box, Button, CircularProgress, Divider, IconButton, Paper, Typography, alpha } from "@mui/material";
import React, { FC, useEffect } from "react";
import { ConfirmDialogEnum, ConfirmDialogType, ILanguage, ITranslation } from "../../utils/types";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreateQuoteTranslationMutation,
  useDeleteQuoteTranslationMutation,
  useEditQuoteTranslationMutation,
  useGetLanguagesQuery,
  useGetQuoteTranslationsQuery,
} from "../../api/apiSlice";
import toast from "react-hot-toast";
import { TEMPORARY_ID, TranslationComponent } from "./TranslationComponent";
import { ConfirmDialog } from "../../components";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../hooks";

interface CreateOrEditTranslationsProps {
  initialValues?: ITranslation[] | null;
  handleClose: () => void;
  isEditing?: boolean;
  quoteId: number;
}

// Putting a random high number to avoid pagination and second request calls
const PER_PAGE_COUNT = 99999;
const OPEN_ACTION_ERROR = "Please close the current action before proceeding";
const NO_TRANSLATION_TEXT = "No translations added";

export const CreateOrEditTranslations: FC<CreateOrEditTranslationsProps> = props => {
  const { handleClose, quoteId, initialValues } = props;

  const { data, isLoading: isLoadingGetTranslations } = useGetQuoteTranslationsQuery({
    quoteId,
  });

  const [translations, setTranslations] = React.useState<Partial<ITranslation>[]>(data || initialValues || []);
  const [isAddingTranslation, setIsAddingTranslation] = React.useState<boolean>(false);
  const [isEditingTranslation, setIsEditingTranslation] = React.useState<Partial<ITranslation> | null>(null);
  const [currentTranslation, setCurrentTranslation] = React.useState<Partial<ITranslation> | null>(null);
  const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState<ConfirmDialogType | undefined>();
  const [showTranslationComponentError, setShowTranslationComponentError] = React.useState<{
    translation: Partial<ITranslation>;
    error: string;
  } | null>(null);

  const [createQuoteTranslation, createQuoteTranslationResult] = useCreateQuoteTranslationMutation();
  const [editQuoteTranslation, editQuoteTranslationResult] = useEditQuoteTranslationMutation();
  const [deleteQuoteTranslation, deleteQuoteTranslationResult] = useDeleteQuoteTranslationMutation();
  const { isLoading: isLoadingCreate, isSuccess: isSuccessCreate } = createQuoteTranslationResult;
  const { isLoading: isLoadingEdit, isSuccess: isSuccessEdit } = editQuoteTranslationResult;
  const { isLoading: isLoadingDelete, isSuccess: isSuccessDelete } = deleteQuoteTranslationResult;

  const isLoading = isLoadingCreate || isLoadingEdit;

  const { data: rowLanguageData } = useGetLanguagesQuery({
    per_page: PER_PAGE_COUNT,
    published: 1,
  });

  React.useEffect(() => {
    if (data) {
      setTranslations(data);
    }
  }, [data]);

  const displayLanguages = React.useMemo(() => {
    return (rowLanguageData?.data?.map((lang: ILanguage) => lang.lang) || []).sort();
  }, [rowLanguageData?.data]);

  const resetAllStates = () => {
    setIsAddingTranslation(false);
    setIsEditingTranslation(null);
    setCurrentTranslation(null);
    setShowTranslationComponentError(null);
    setOpenedConfirmDialog(undefined);
  };

  const canCreateTranslation = useHasPermission(UserPermissions.CREATE_TRANSLATIONS);

  const handleSaveConfirmDialog = () => {
    const { id, lang, text } = currentTranslation || {};

    if (isEditingTranslation && id && lang && text && quoteId) {
      editQuoteTranslation({
        id,
        lang,
        text,
        quoteId,
      })
        .unwrap()
        .then((data: ITranslation) => {
          const updatedTranslations = translations.map(translation =>
            translation.id?.toString() === id?.toString() ? { ...data } : translation,
          );
          setTranslations(updatedTranslations);
          toast.success("Changes saved successfully");
          resetAllStates();
        })
        .catch(error => {
          toast.error(error?.data || "Failed to save changes");
        });

      return;
    }

    if (isAddingTranslation && lang && text && quoteId) {
      createQuoteTranslation({
        lang,
        text,
        quoteId,
      })
        .unwrap()
        .then((data: ITranslation) => {
          const updatedTranslations = translations.map(translation => (translation.id === TEMPORARY_ID ? { ...data } : translation));
          setTranslations(updatedTranslations);
          toast.success("Translation added successfully");
          resetAllStates();
        })
        .catch(error => {
          toast.error(error?.data || "Failed to create translation");
        });

      return;
    }
  };

  const handleSaveTranslation = (data: Partial<ITranslation>) => {
    setCurrentTranslation(data);
    setOpenedConfirmDialog(ConfirmDialogEnum.Save);
  };

  const handleCloseAddOrEditTranslation = () => {
    // If are closing while adding a new translation and not saving it, remove the temporary translation
    if (currentTranslation?.id === TEMPORARY_ID && isAddingTranslation) {
      setTranslations(translations?.filter(translation => translation.id !== TEMPORARY_ID) || []);
    }
    resetAllStates();
  };

  const handleEditTranslation = (data: Partial<ITranslation>) => {
    // If we try editing while adding or editing another translation, show error
    if (currentTranslation && (isAddingTranslation || isEditingTranslation)) {
      setShowTranslationComponentError({
        translation: currentTranslation,
        error: OPEN_ACTION_ERROR,
      });
      return;
    }
    setIsEditingTranslation(data);
    setCurrentTranslation(data);
  };

  const handleDeleteConfirmDialog = () => {
    if (!currentTranslation?.id) {
      return;
    }
    deleteQuoteTranslation({
      id: currentTranslation.id,
      quoteId,
    })
      .unwrap()
      .then(() => {
        const updatedTranslations = translations.filter(translation => translation.id !== currentTranslation.id);
        setTranslations(updatedTranslations);
        toast.success("Translation removed successfully");
        resetAllStates();
      })
      .catch(error => {
        toast.error(error?.data || "Failed to remove translation");
        resetAllStates();
      });
  };

  const handleRemoveTranslation = (data: Partial<ITranslation>) => {
    setOpenedConfirmDialog(ConfirmDialogEnum.Delete);
    setCurrentTranslation(data);
  };

  const handleOnChangeTranslation = (data: Partial<ITranslation>) => {
    setCurrentTranslation(data);
    setShowTranslationComponentError(null);
  };

  const handleOpenAddNewTranslation = () => {
    // Prevent adding a new translation if another translation is currently being added or edited.
    // If a translation is in progress, display an error message indicating that the action cannot be performed.
    if (currentTranslation && (isAddingTranslation || isEditingTranslation)) {
      setShowTranslationComponentError({
        translation: currentTranslation,
        error: OPEN_ACTION_ERROR,
      });
      return;
    }

    setIsAddingTranslation(true);
    const newTranslation = {
      id: TEMPORARY_ID,
      lang: "",
      text: "",
      published: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
    } as ITranslation;
    setCurrentTranslation(newTranslation);
    setTranslations([...(translations || []), newTranslation]);
  };

  useEffect(() => {
    if (isSuccessCreate || isSuccessEdit || isSuccessDelete) {
      // Do nothing for now
      // handleClose();
    }
  }, [handleClose, isSuccessCreate, isSuccessDelete, isSuccessEdit]);

  const handleConfirmDialogClose = () => {
    setOpenedConfirmDialog(undefined);
  };

  return (
    <Paper
      sx={theme => ({
        background: alpha(theme.palette.primary.main, 0.12),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: 500,
        maxWidth: 500,
        overflowY: "auto",
      })}
    >
      <Box display="flex" justify-content="space-between" alignItems="center" sx={{ padding: "24px 24px 0", width: "100%" }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          Translations
        </Typography>
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
        <Box display="flex" flexDirection={"column"} sx={{ p: 2, gap: 1 }}>
          {isLoadingGetTranslations && translations?.length === 0 && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}

          {translations?.length === 0 && !isLoadingGetTranslations && (
            <Typography align="center" color="textSecondary">
              {NO_TRANSLATION_TEXT}
            </Typography>
          )}

          {translations?.map((translation: Partial<ITranslation>, index) => (
            <TranslationComponent
              key={translation?.id || index}
              translation={translation}
              isAddingTranslation={isAddingTranslation}
              isEditingTranslation={isEditingTranslation}
              currentTranslation={currentTranslation}
              languages={displayLanguages}
              handleSaveTranslation={handleSaveTranslation}
              handleEditTranslation={handleEditTranslation}
              handleOnChangeTranslation={handleOnChangeTranslation}
              showTranslationComponentError={showTranslationComponentError}
              handleCloseAddOrEditTranslation={handleCloseAddOrEditTranslation}
              handleRemoveTranslation={handleRemoveTranslation}
              isLoading={isLoading && translation.id === currentTranslation?.id}
              isLoadingDelete={isLoadingDelete && translation.id === currentTranslation?.id}
            />
          ))}
          {canCreateTranslation && (
            <Box>
              <Button
                onClick={handleOpenAddNewTranslation}
                sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5), mt: 2 })}
                disableElevation
                color="primary"
                variant="contained"
                type="button"
              >
                Add New
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          <Divider />
          <Box display="flex" justifyContent="flex-end" sx={{ p: 3, position: "relative", bottom: 0, gap: 2 }}>
            <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
              <Typography sx={{ fontSize: "16px" }} color="textPrimary">
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      {openedConfirmDialog === ConfirmDialogEnum.Delete && (
        <ConfirmDialog
          open={true}
          title={`Remove Translation: ${currentTranslation?.lang}`}
          body="Are you sure you want to remove this translation? This action is permanent and cannot be undone."
          onClose={handleConfirmDialogClose}
          onConfirm={() => handleDeleteConfirmDialog()}
          loading={isLoadingDelete}
        />
      )}
      {openedConfirmDialog === ConfirmDialogEnum.Save && (
        <ConfirmDialog
          open={true}
          title={`Save Translation: ${currentTranslation?.lang}`}
          body="Are you sure you want to save this translation?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handleSaveConfirmDialog()}
          loading={isLoading}
        />
      )}
    </Paper>
  );
};
