import React from "react";
import { Box, Button, Chip, Divider, Typography, Dialog } from "@mui/material";
import { User } from "../../utils/types";
import { TextView } from "../../components/TextView/TextView";
import { UserTabEnum } from "./types";
import HistoryIcon from "@mui/icons-material/History";
import { ViewUserLoginHistory } from "./ViewUserLoginHistory";
import { userFriendlyDateTimestamp } from "../../utils/timestamps";

interface Props {
  activeTab: UserTabEnum;
  user: User | null;
  handleClose: () => void;
}

export const ViewUser: React.FC<Props> = props => {
  const { user, handleClose } = props;

  const [openUserHistory, setOpenUserHistory] = React.useState<boolean>(false);

  if (!user) {
    return (
      <Typography variant="h5" component="h2">
        No user selected
      </Typography>
    );
  }

  const handleOpenUserHistory = () => {
    setOpenUserHistory(true);
  };

  const handleCloseUserHistory = () => {
    setOpenUserHistory(false);
  };

  const LoginHistoryIcon: React.ReactNode = (
    <Button onClick={handleOpenUserHistory}>
      <Box display="flex" alignItems="center" gap={0.5}>
        <HistoryIcon color="primary" fontSize="small" />
        <Typography color="primary" variant="caption">
          Login History
        </Typography>
      </Box>
    </Button>
  );

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: "100%" }}>
      <Dialog open={openUserHistory} onClose={handleCloseUserHistory} fullWidth maxWidth="lg">
        <ViewUserLoginHistory handleClose={handleCloseUserHistory} user={user} />
      </Dialog>
      <Box>
        <TextView label="Full Name" value={user.name} />
        <TextView label="Email" value={user.email} />
        <TextView
          label="Roles"
          value={
            user.roles?.length ? (
              <Box display="flex" sx={{ gap: 1 }}>
                {user.roles.map(({ role, id }) => (
                  <Chip size="small" key={id} label={role} />
                ))}
              </Box>
            ) : (
              "User has no roles"
            )
          }
        />
        <TextView label="Status" value={user.status.charAt(0).toUpperCase() + user.status.slice(1)} />
        <TextView label="Created At" value={userFriendlyDateTimestamp(user.createdAt)} />
        <TextView label="Last Login" value={userFriendlyDateTimestamp(user.lastLoginAt || "")} actionComponent={LoginHistoryIcon} />
        <TextView label="Current Subsciption" value={user.subscription} />
        <TextView label="Subscribed Date" value={userFriendlyDateTimestamp(user.subscribedAt || "")} />
        <TextView label="Un-Subscribed Date" value={userFriendlyDateTimestamp(user.unsubscribedAt || "")} />
        <TextView label="Total Shares" value={user.totalShares} />
        <TextView label="Total Likes" value={user.totalLikes} />
        <TextView label="Total Hides" value={user.totalHides} />
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box display="flex" justifyContent="flex-end" sx={{ position: "relative", bottom: 0, gap: 3 }}>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
