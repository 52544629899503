import { Box, Button, CircularProgress, Divider, TextField, Typography, alpha } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Reference, SubmitButtonTextEnum, SubmitButtonEnum, SubmitButtonType, TabKeyEnum } from "../../utils/types";
import { useCreateReferenceMutation, useEditReferenceMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { useDetectChanges, useDisableButtons } from "../../hooks";

interface CreateOrEditReferenceProps {
  reference?: Reference | null;
  activeTab: TabKeyEnum;
  handleClose: () => void;
  isEditing?: boolean;
}
export const CreateOrEditReference: FC<CreateOrEditReferenceProps> = props => {
  const { reference, handleClose, isEditing, activeTab } = props;

  const [referenceDetails, setReferenceDetails] = React.useState<Partial<Reference> | null>(reference || null);
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReferenceDetails({ ...referenceDetails, text: event.target.value });

    if (event.target.value) {
      setError(null);
      return;
    }
    setError("Reference is required");
  };

  const [createReference, createReferenceResult] = useCreateReferenceMutation();
  const [editReference, editReferenceResult] = useEditReferenceMutation();
  const { isLoading: isLoadingAddReference, isSuccess } = createReferenceResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editReferenceResult;

  const isLoading = isLoadingAddReference || isEditLoading;

  const handleSubmit = (published: -1 | 1 | 0, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);
    if (referenceDetails?.text) {
      const text = referenceDetails.text.trim();
      if (isEditing && referenceDetails?.id) {
        editReference({
          id: referenceDetails.id,
          text,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
      } else {
        createReference({
          text,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Reference added successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to create reference");
          });
      }
    }
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  const hasChanges = useDetectChanges<Partial<Reference>>({ initialObject: reference, object: referenceDetails });

  const hasRequiredFields = !!referenceDetails?.text?.trim();
  const { disableReadyForReviewButton, disableSaveAsUnpublishedButton } = useDisableButtons({
    isLoading,
    hasRequiredFields,
    isEditing: !!isEditing,
    hasChanges: hasChanges(),
    activeTab,
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <TextField
        id="text"
        label="Reference"
        value={referenceDetails?.text || ""}
        onChange={handleOnChange}
        onBlur={handleOnChange}
        error={!!error}
        helperText={error}
        fullWidth
        multiline
        required
        margin="normal"
      />
      <Box>
        <Divider />

        <Box display="flex" justifyContent="flex-end" sx={{ p: 3, position: "relative", bottom: 0, gap: 2 }}>
          <Button
            onClick={() => handleSubmit(-1, SubmitButtonEnum.ReadyForReview)}
            disabled={disableReadyForReviewButton}
            sx={{ borderRadius: "20px" }}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {isLoading && clickedButton === SubmitButtonEnum.ReadyForReview ? <CircularProgress size={16} /> : ""}
            {SubmitButtonTextEnum.ReadyForReview}
          </Button>
          <Button
            onClick={() => handleSubmit(0, SubmitButtonEnum.SaveAsUnpublished)}
            disabled={disableSaveAsUnpublishedButton}
            disableElevation
            color="primary"
            sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            variant="contained"
            type="button"
          >
            {isLoading && clickedButton === SubmitButtonEnum.SaveAsUnpublished ? <CircularProgress size={16} /> : ""}
            {SubmitButtonTextEnum.SaveAsUnpublished}
          </Button>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
