import { combineReducers } from "@reduxjs/toolkit";
import { api } from "../api/apiSlice";
import { ipApi } from "../api/thirdPartyApiSlice";

// Combine the individual reducers into a single reducer function
const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [ipApi.reducerPath]: ipApi.reducer,
});

export default reducer;
