import reducer from "./reducers";
import configureMiddlewares from "./middlewares";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => configureMiddlewares(getDefaultMiddleware),
});

export default store;
