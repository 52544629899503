import { MenuItem } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { OneLiner } from "../../components/Table/Table";
import { TabKeyEnum, TableMenuItemInterface } from "../../utils/types";
import { MouseEvent, useCallback, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { userFriendlyDateTimestamp } from "../../utils/timestamps";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handleEditLanguage: () => void;
  handleUnpublishLanguage: () => void;
  handlePublishLanguage: () => void;
  handlePreviewLanguage: () => void;
  handleRemoveLanguage: () => void;
}

export const useGetLanguagesTableColumns = (params: Params) => {
  const {
    setHoveredRow,
    hoveredRow,
    activeTab,
    handlePreviewLanguage,
    handleEditLanguage,
    handleUnpublishLanguage,
    handlePublishLanguage,
    handleRemoveLanguage,
  } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewLanguageSelection = useCallback(() => {
    setShouldCloseMenuSelection(true);
    handlePreviewLanguage();
  }, [handlePreviewLanguage, setShouldCloseMenuSelection]);

  const handleEditSelectedLanguage = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditLanguage();
    },
    [handleEditLanguage, setShouldCloseMenuSelection],
  );

  const handleUnpublishSelectedLanguage = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishLanguage();
    },
    [handleUnpublishLanguage],
  );

  const handlePublishSelectedLanguage = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishLanguage();
    },
    [handlePublishLanguage],
  );

  const handleRemoveSelectedLanguage = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveLanguage();
    },
    [handleRemoveLanguage, setShouldCloseMenuSelection],
  );

  const canEditReadyAndUnPublishedLanguages = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES);
  const canApprovePublishLanguages = useHasPermission(UserPermissions.APPROVE_PUBLISH_LANGUAGES);
  const canRemoveLanguages = useHasPermission(UserPermissions.REMOVE_LANGUAGES);
  const canUnpublishLanguages = useHasPermission(UserPermissions.UNPUBLISH_LANGUAGES);

  const menuItems: TableMenuItemInterface[] = [
    {
      label: "Approve & Publish",
      onClick: handlePublishSelectedLanguage,
      visible: activeTab === TabKeyEnum.ReadyForReview && canApprovePublishLanguages,
    },
    {
      label: "Preview",
      onClick: handlePreviewLanguageSelection,
      visible: true,
    },
    {
      label: "Edit",
      onClick: handleEditSelectedLanguage,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedLanguages,
    },
    {
      label: "Unpublish",
      onClick: handleUnpublishSelectedLanguage,
      visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishLanguages,
    },
    {
      label: "Remove",
      onClick: handleRemoveSelectedLanguage,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveLanguages,
      sx: { color: "red" },
    },
  ];

  const columns: MRT_ColumnDef<any>[] = [
    { accessorKey: "id", header: "ID", size: 150, Cell: ({ row }: any) => <OneLiner text={row.original.id} /> },
    {
      accessorKey: "lang",
      header: "Language",
      size: 300,
      Cell: ({ row }) => <OneLiner text={row.original.lang} />,
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      Cell: ({ row }) => userFriendlyDateTimestamp(row.original.createdAt),
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      Cell: ({ row }) => <OneLiner text={row.original.createdBy?.name} />,
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
      Cell: ({ row }) => userFriendlyDateTimestamp(row.original.updatedAt),
    },
    {
      accessorKey: "updatedBy",
      header: "Updated By",
      Cell: ({ row }) => <OneLiner text={row.original.updatedBy?.name} />,
    },
    {
      accessorKey: "action",
      id: "action",
      size: 50,
      header: "",
      enableResizing: false,
      muiTableHeadCellProps: () => ({
        sx: {
          visibility: "hidden",
        },
      }),
      Cell: ({ row }: any) => {
        if (hoveredRow !== row.index) return null;
        return (
          <ActionCell
            shouldCloseMenuSelection={shouldCloseMenuSelection}
            setShouldCloseMenuSelection={setShouldCloseMenuSelection}
            hovered={hoveredRow === row.index}
            loading={false}
            activeTab={activeTab}
            onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
              event?.stopPropagation?.();
              setHoveredRow(row.index);
            }}
          >
            {menuItems.map(
              (item, index) =>
                item.visible && (
                  <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                ),
            )}
          </ActionCell>
        );
      },
    },
  ];

  return columns;
};
