import { useCallback, useRef } from "react";

type Props<T> = {
  initialObject: T | null | undefined;
  object: T | null | undefined;
  ignoreKeys?: (keyof T)[];
};

// Checks for changes every time the function is called.
// If the values revert back to their original state, the function will return false.
export const useDetectChanges = <T extends object>(props: Props<T>) => {
  const { initialObject, object, ignoreKeys } = props;
  return useCallback(() => {
    return Object.entries(object || {}).some(([key, value]) => {
      if (ignoreKeys?.includes(key as keyof T)) {
        return false;
      }
      const typedKey = key as keyof T;

      if (typeof value === "object" && value !== null) {
        // Handle nested objects recursively
        return detectChangesNested(value, initialObject?.[typedKey]);
      }

      return value !== initialObject?.[typedKey];
    });
  }, [ignoreKeys, initialObject, object]);
};

// Checks for changes only once and considers any change as a permanent change,
// even if the values revert back to their original state, the function will still return true.
export const useDetectAnyChange = <T extends object>(props: Props<T>) => {
  const { initialObject, object, ignoreKeys } = props;
  const initialReference = useRef(initialObject);
  const hasDetectedChange = useRef(false);

  const checkForChanges = useCallback(() => {
    if (hasDetectedChange.current) {
      return true;
    }

    const hasChanges = Object.entries(object || {}).some(([key, value]) => {
      if (ignoreKeys?.includes(key as keyof T)) {
        return false;
      }
      const typedKey = key as keyof T;

      return detectChangesNested(value, initialReference.current?.[typedKey]);
    });

    if (hasChanges) {
      hasDetectedChange.current = true;
    }

    return hasChanges;
  }, [ignoreKeys, object]);

  return checkForChanges;
};

const detectChangesNested = (newValue: any, initialValue: any): boolean => {
  if (newValue === initialValue) {
    return false;
  }

  if (newValue === null || initialValue === null) {
    return newValue !== initialValue;
  }

  if (Array.isArray(newValue) && Array.isArray(initialValue)) {
    // Handle arrays
    if (newValue.length !== initialValue.length) {
      return true;
    }
    return newValue.some((item, index) => detectChangesNested(item, initialValue[index]));
  }

  if (typeof newValue === "object" && typeof initialValue === "object") {
    // Handle nested objects
    const newKeys = Object.keys(newValue);
    const initialKeys = Object.keys(initialValue);

    if (newKeys.length !== initialKeys.length) {
      return true;
    }

    return newKeys.some(key => detectChangesNested(newValue[key], initialValue[key]));
  }

  // Direct comparison for non-nested values
  return newValue !== initialValue;
};
