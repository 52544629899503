import * as React from "react";
import { useDeleteReferenceMutation, useEditReferenceMutation, useGetReferencesQuery } from "../../api/apiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { ConfirmDialog, CustomTabPanel, Table } from "../../components";
import { useGetReferencesTableColumns } from "./useGetReferencesTableColumns";
import { appendSearchParams } from "../../utils";
import { ConfirmDialogEnum, ConfirmDialogType, ModalEnum, ModalType, Reference, TabKeyEnum } from "../../utils/types";
import { MRT_ColumnDef } from "material-react-table";
import { ViewReference } from "./ViewReference";
import { Drawer } from "../../components/Drawer/Drawer";
import { CreateOrEditReference } from "./CreateOrEditReference";
import toast from "react-hot-toast";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { PageTabParams } from "../../utils/constants";

export const References = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [hoveredRow, setHoveredRow] = React.useState(-1);
  const [selectedReference, setSelectedReference] = React.useState<Reference | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<ModalType | undefined>();
  const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState<ConfirmDialogType | undefined>();
  const [filter, setFilter] = React.useState<string | undefined>();

  const [editReference, editResult] = useEditReferenceMutation();
  const [deleteReference, deleteResult] = useDeleteReferenceMutation();

  const canCreateReference = useHasPermission(UserPermissions.CREATE_REFERENCES);

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTab = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTab].params;
  const { data, isLoading, isFetching } = useGetReferencesQuery({
    ...apiSupportedParams,
    text: filter,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const total = data?.total || 0;
  const referencesState = React.useMemo(() => data?.data || [], [data]);
  const referencesData = React.useMemo<MRT_ColumnDef<Reference>[]>(
    () =>
      referencesState?.map((ref: Reference) => ({
        ...ref,
        id: ref.id,
        text: ref.text,
        createdAt: ref.createdAt,
        createdBy: ref.createdBy,
        updatedBy: ref.updatedBy,
        updatedAt: ref.updatedAt,
      })),
    [referencesState],
  );

  const getDrawerTitle = () => {
    if (isDrawerOpen === ModalEnum.Preview) {
      return "Preview Reference";
    }
    if (isDrawerOpen === ModalEnum.Create) {
      return "Create Reference";
    }
    if (isDrawerOpen === ModalEnum.Edit) {
      return "Edit Reference";
    }
    return "";
  };

  const handlePreviewReference = () => {
    setIsDrawerOpen(ModalEnum.Preview);
  };

  const handleCreateReference = () => {
    setSelectedReference(null);
    setHoveredRow(-1);
    setIsDrawerOpen(ModalEnum.Create);
  };

  const handleEditReference = () => {
    setIsDrawerOpen(ModalEnum.Edit);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(undefined);
  };

  const handleToggleConfirmDialog = (dialog: ConfirmDialogType) => {
    setOpenedConfirmDialog(dialog);
  };

  const handleConfirmDialogClose = () => {
    setOpenedConfirmDialog(undefined);
  };

  const handleRemoveReference = () => {
    if (selectedReference?.id) {
      deleteReference({ id: selectedReference.id })
        .unwrap()
        .then(() => {
          handleConfirmDialogClose();
          toast.success("Reference removed successfully");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to removed reference");
        });
    } else {
      console.error("No reference selected");
    }
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Reference published successfully",
        error: "Failed to publish reference",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Reference saved successfully",
        error: "Failed to saved reference",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Reference unpublish successfully",
        error: "Failed to unpublish reference",
      },
    };

    if (selectedReference) {
      editReference({
        id: selectedReference.id,
        published: messages[published].value,
        text: selectedReference.text,
      })
        .unwrap()
        .then(() => {
          handleConfirmDialogClose();
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.data || messages[published].error);
        });
    } else {
      console.error("No reference selected");
    }
  };

  const columns = useGetReferencesTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handlePreviewReference,
    handleEditReference,
    handlePublishReference: () => handleToggleConfirmDialog(ConfirmDialogEnum.Publish),
    handleUnpublishReference: () => handleToggleConfirmDialog(ConfirmDialogEnum.Unpublish),
    handleRemoveReference: () => handleToggleConfirmDialog(ConfirmDialogEnum.Delete),
  });

  return (
    <Box>
      <Drawer title={getDrawerTitle()} onClose={handleDrawerClose} open={!!isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen === ModalEnum.Preview && (
          <ViewReference
            handleToggleConfirmDialog={handleToggleConfirmDialog}
            activeTab={activeTabParam}
            reference={selectedReference}
            handleClose={handleDrawerClose}
            handleEditReference={handleEditReference}
          />
        )}
        {(isDrawerOpen === ModalEnum.Create || isDrawerOpen === ModalEnum.Edit) && (
          <CreateOrEditReference
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            reference={selectedReference}
            isEditing={isDrawerOpen === ModalEnum.Edit}
          />
        )}
      </Drawer>

      {openedConfirmDialog === ConfirmDialogEnum.Unpublish && (
        <ConfirmDialog
          open={true}
          title="Unpublish Reference"
          body="Are you sure you want to unpublish this reference?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
          loading={editResult.isLoading}
        />
      )}
      {openedConfirmDialog === ConfirmDialogEnum.Publish && (
        <ConfirmDialog
          open={true}
          title="Publish Reference"
          body="Are you sure you want to publish this reference?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
          loading={editResult.isLoading}
        />
      )}
      {openedConfirmDialog === ConfirmDialogEnum.Save && (
        <ConfirmDialog
          open={true}
          title="Save Reference"
          body="Are you sure you want to save this reference?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
          loading={editResult.isLoading}
        />
      )}
      {openedConfirmDialog === ConfirmDialogEnum.Delete && (
        <ConfirmDialog
          open={true}
          title="Remove Reference"
          body="Are you sure you want to delete this reference? This action is permanent and cannot be undone."
          onClose={handleConfirmDialogClose}
          onConfirm={handleRemoveReference}
          loading={deleteResult.isLoading}
        />
      )}
      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTab} onChange={handleTabChange}>
          {PageTabParams.map((value, index: number) => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateReference && (
          <Box>
            <Button sx={{ borderRadius: 0.5 }} color="primary" variant="outlined" onClick={handleCreateReference}>
              CREATE REFERENCE
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTab} index={activeTab}>
          <Table
            columns={columns}
            data={referencesData}
            manualPagination
            onPaginationChange={setPagination}
            manualFiltering
            onGlobalFilterChange={setFilter}
            enableSorting={false}
            state={{ pagination }}
            rowCount={total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: Reference } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
                setSelectedReference(row.original);
              },
              onMouseLeave: () => {
                setHoveredRow(-1);
              },
              onClick: () => {
                setSelectedReference(row.original);
                handlePreviewReference();
              },
              sx: (theme: any) => ({
                backgroundColor: hoveredRow === row.index ? theme.palette.primary.main : "inherit",
                ":hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }),
            })}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};
