import { Button, Typography, Box, IconButton, Chip, Avatar, FormHelperText } from "@mui/material";
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { upload } from "./uploadController";
import CircularProgressBar from "./ProgressBar";
import { IFileUploadHandle } from "../../utils/types";
import { getNameFromPath } from "../../utils";
import toast from "react-hot-toast";

interface Props {
  imgSrc?: string | null;
  getFileUploadError: (error: string | null) => void;
  getUploadedFile?: (file: File) => void;
}
interface ErrorResponse {
  response?: {
    data?: {
      errors?: string[];
    };
  };
}

const isUploadError = (error: any): error is ErrorResponse => {
  return error && error.response && error.response.data && Array.isArray(error.response.data.errors);
};

const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/svg+xml"]; // jpeg, png, svg
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES_STRING = ALLOWED_TYPES.join(",");

const FileUpload = forwardRef<IFileUploadHandle, Props>((props, ref) => {
  const { imgSrc = null, getFileUploadError, getUploadedFile } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string>(imgSrc ? `/api${imgSrc}` : "");
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const onPercentChange = (progress: number) => {
    setUploadProgress(progress);
  };

  useImperativeHandle(ref, () => ({
    //need to call uploadFile from parent, NOT on every "select file" click
    uploadFile: async (): Promise<string | null> => {
      //is pristine
      if (imgSrc && imageSrc && !selectedFile) return getNameFromPath(imgSrc);

      if (!selectedFile || error) return null;

      setLoading(true);
      const { data, error: uploadError } = await upload(selectedFile, onPercentChange);
      let message = ["An error occured while trying to upload the image, please try again"];

      setError(null);
      setLoading(false);

      if (uploadError) {
        if (isUploadError(uploadError)) {
          message = uploadError.response?.data?.errors || message;
        }
        message.forEach(msg => toast.error(msg));
        return "error";
      }
      return data[0];
    },
  }));

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (!file) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);
    setImageSrc(URL.createObjectURL(file));

    // Pass the file to the parent component
    getUploadedFile && getUploadedFile(file);

    const validateFile = (file: File) => {
      if (!ALLOWED_TYPES.includes(file.type)) {
        const message = "Unsupported file type";
        setError(message);
        getFileUploadError(message);
        toast.error(message);
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        const message = "File size exceeds the 5MB limit. Please upload a smaller file";

        setError(message);
        getFileUploadError(message);
        toast.error(message);
        return false;
      }

      setError(null);
      getFileUploadError(null);
      return true;
    };

    if (!validateFile(file)) return;
  };

  const handleRemove = () => {
    setError(null);
    getFileUploadError(null);
    setSelectedFile(null);
    setImageSrc("");
    getUploadedFile && getUploadedFile({} as File);
  };
  return (
    <>
      <Button
        sx={theme => {
          return {
            border: `1px dotted ${theme.palette.primary.main}`,
          };
        }}
        disabled={loading}
        size="large"
        component="label"
      >
        Select File
        <input onChange={handleChange} type="file" accept={ALLOWED_TYPES_STRING} hidden />
      </Button>
      {(selectedFile || imageSrc !== "") && (
        <Box display="flex" alignItems="center" gap={1}>
          {loading ? (
            <CircularProgressBar value={uploadProgress} />
          ) : (
            <IconButton onClick={handleRemove} color={error ? "error" : "default"}>
              <CloseIcon />
            </IconButton>
          )}
          <Chip
            avatar={<Avatar src={imageSrc}></Avatar>}
            label={<Typography color={error ? "red" : "textPrimary"}>{selectedFile?.name || getNameFromPath(imgSrc || "")}</Typography>}
          />
        </Box>
      )}
      <FormHelperText id="component-helper-text">Supported file types: .jpg, .png, .svg of Max 5MB</FormHelperText>
    </>
  );
});

export default FileUpload;
