import { useLogoutMutation, useRefreshMutation } from "../api/apiSlice";
import { decodedToken } from "../utils";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMemo } from "react";

interface DecodedToken {
  user: TokenUser;
  iat: number;
  exp: number;
  id: number;
}

interface TokenUser {
  id: number;
  name: string;
  email: string;
  avatar: string | null;
  status: string;
  subscription: string | null;
  lastLoginAt: string;
  subscribedAt: string | null;
  unsubscribedAt: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

const cookies = Cookies;

export const useAuth = () => {
  const [logout] = useLogoutMutation();
  const [refresh, refreshTokenResult] = useRefreshMutation();
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  const tokenData = useMemo(() => {
    if (accessToken) {
      const decoded = decodedToken(accessToken) as DecodedToken;
      return decoded;
    }
    return null;
  }, [accessToken]);

  const { user, exp: tokenExpiry = 0 } = tokenData || {};

  const setLocalStorage = (data: { accessToken: string; refreshToken: string }) => {
    cookies.set("accessToken", data.accessToken, { path: "/" });
    cookies.set("refreshToken", data.refreshToken, { path: "/" });
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
  };

  const removeLocalStorage = () => {
    cookies.remove("accessToken");
    cookies.remove("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const logoutAction = () => {
    navigate("/");
    removeLocalStorage();
    logout()
      .unwrap()
      .then(() => {
        removeLocalStorage();
      })
      .catch(error => {
        console.log(error?.data || "Failed to logout");
      });
  };

  const refreshTokenAction = () => {
    refresh()
      .unwrap()
      .then(data => {
        setLocalStorage(data);
      })
      .catch(error => {
        console.log(error?.data || "Failed to refresh token");
      });
  };

  return {
    logoutAction,
    refreshTokenAction,
    refreshTokenResult,
    user,
    tokenExpiry,
    setLocalStorage,
    removeLocalStorage,
    accessToken,
    refreshToken,
  };
};
