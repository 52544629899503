import React, { ReactNode, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CottageIcon from "@mui/icons-material/Cottage";
import PersonIcon from "@mui/icons-material/Person2";
import SourceIcon from "@mui/icons-material/Source";
import { useIdleTimer } from "react-idle-timer";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import LanguageIcon from "@mui/icons-material/Language";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, alpha, useMediaQuery } from "@mui/material";
import AccountMenu from "./AccountMenu";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Avatar } from "../../components/Avatar/Avatar";
import LogoIcon from "../../images/logo.svg";
import Search from "../../components/Search/Search";
import { useHasAdminRole } from "../../hooks/usePermissions";
import { useAuth } from "../../hooks";

const drawerWidth = 250;

interface MenuItem {
  label: string;
  icon: ReactNode;
  path: string;
  visible?: boolean;
}

const Main = styled("main", {
  shouldForwardProp: prop => !(["open", "isMobile"] as any).includes(prop),
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${isMobile ? 0 : drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${isMobile ? 0 : drawerWidth}px`,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  width: "100%",
}));

interface Props {
  children: React.ReactNode;
}

// Temporarily hide search bar for now. Not required yet.
const showSearchComponent = false;

const REFRESH_TOKEN_TIMING_MS = 1000 * 60 * 10; // 10 minutes in milliseconds
const IDLE_TIMEOUT_MS = 1000 * 60 * 30; // 30 minutes in milliseconds
const PROMPT_BEFORE_IDLE_MS = 30000; // 30 seconds in milliseconds

function Wrapper(props: Props): JSX.Element {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(!isMobile);
  const [openIdleTimeoutModal, setOpenIdleTimeoutModal] = React.useState(false);

  const { logoutAction, user, refreshTokenAction, refreshTokenResult, accessToken, tokenExpiry } = useAuth();

  const onPrompt = () => {
    setOpenIdleTimeoutModal(true);
  };

  const onIdle = () => {
    setOpenIdleTimeoutModal(false);
    logoutAction();
  };

  const onActive = (event: any) => {
    setOpenIdleTimeoutModal(false);
  };

  const { reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    // Automatically log out the user after 30 minutes of inactivity
    timeout: IDLE_TIMEOUT_MS,
    // Alert the user 30 seconds before automatic logout due to inactivity
    promptBeforeIdle: PROMPT_BEFORE_IDLE_MS,
  });

  useEffect(() => {
    if (!isMobile) {
      setOpen(true);
    }
  }, [isMobile]);

  const handleCloseIdleModal = () => {
    setOpenIdleTimeoutModal(false);
    reset();
  };

  const handleAcceptLogoutNow = () => {
    setOpenIdleTimeoutModal(false);
    logoutAction();
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { hasAdminRole: canManageUsers } = useHasAdminRole();

  const menuItems: MenuItem[] = [
    {
      label: "Users",
      icon: <PeopleIcon color="primary" />,
      path: "/users",
      visible: canManageUsers,
    },
    {
      label: "Quotes",
      icon: <FormatQuoteIcon color="primary" />,
      path: "/quotes",
      visible: true,
    },
    {
      label: "Cultures",
      icon: <CottageIcon color="primary" />,
      path: "/cultures",
      visible: true,
    },
    {
      label: "Authors",
      icon: <PersonIcon color="primary" />,
      path: "/authors",
      visible: true,
    },
    {
      label: "Sources",
      icon: <SourceIcon color="primary" />,
      path: "/sources",
      visible: true,
    },
    {
      label: "Tags",
      icon: <LocalOfferIcon color="primary" />,
      path: "/tags",
      visible: true,
    },
    {
      label: "Languages",
      icon: <LanguageIcon color="primary" />,
      path: "/languages",
      visible: true,
    },
    {
      label: "References",
      icon: <RoomPreferencesIcon color="primary" />,
      path: "/references",
      visible: true,
    },
  ];

  const { pathname } = useLocation();
  const currentPath = menuItems.find(({ path }) => path?.toLowerCase() === pathname?.toLowerCase());

  useEffect(() => {
    if (accessToken) {
      const currentTime = Date.now();
      const refreshThreshold = currentTime + REFRESH_TOKEN_TIMING_MS;
      const tokenExpiryTime = tokenExpiry * 1000;

      // Refresh token 10 minutes before expiry
      if (refreshThreshold > tokenExpiryTime) {
        refreshTokenAction();
      }
    }
  }, [accessToken, refreshTokenAction, tokenExpiry]);

  if (refreshTokenResult.isLoading) {
    return <></>;
  }
  if (!accessToken) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box>
      <Dialog
        open={openIdleTimeoutModal}
        onClose={handleCloseIdleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you still there?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have been idle for more 30 minutes. You will be logged out in 30 seconds. Do you want to stay logged in?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" sx={{ border: "1px solid white" }} onClick={handleCloseIdleModal} color="primary">
            <Typography color="textPrimary">Stay logged in</Typography>
          </Button>
          <Button onClick={handleAcceptLogoutNow} color="secondary" autoFocus>
            Log out now
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar
        sx={theme => ({
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          background: theme.palette.background.default,
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <Box component={Link} to="/" display="flex" alignItems="center" sx={{ textDecoration: "none" }} gap={4}>
          <img alt="logo" src={LogoIcon} />
          <Typography color="textPrimary" variant="subtitle2">
            Wisdom
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ flex: 1, width: "100%" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="subtitle1">{currentPath?.label}</Typography> */}
        </Box>
        {showSearchComponent && <Search />}
        <Box display="flex" alignItems="center">
          <AccountMenu accountMenuTooltipTitle={user?.name || ""}>
            <Avatar name={user?.name || ""} />
          </AccountMenu>
        </Box>
        {/* </Toolbar> */}
      </AppBar>
      <Drawer
        sx={theme => ({
          width: drawerWidth,
          flexShrink: 0,
          position: "relative",
          background: alpha(theme.palette.primary.main, 0.12),
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: !isMobile ? alpha(theme.palette.primary.main, 0.12) : theme.palette.background.paper,
          },
        })}
        variant={isMobile ? undefined : "persistent"}
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            p: 2,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
            <Box component={Link} to="/" display="flex" sx={{ textDecoration: "none" }} alignItems="center" flex={1} gap={4}>
              <img alt="logo" src={LogoIcon} />
              <Typography variant="subtitle2">Wisdom</Typography>
            </Box>
            {isMobile && (
              <IconButton onClick={handleDrawerClose}>{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
            )}
          </Box>
        </DrawerHeader>
        {/* <Divider /> */}
        <List sx={{ p: 2 }}>
          {menuItems.map(({ label, icon, path, visible }) => {
            if (!visible) {
              return null;
            }
            return (
              <ListItem sx={{ mb: 2 }} key={path} disablePadding>
                {/* @ts-ignore*/}
                <ListItemButton
                  sx={theme => ({
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    color: theme.palette.primary.main,
                    ...(currentPath?.path === path && {
                      background: theme.palette.background.default,
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: 1,
                    }),
                    height: 40,
                    "&:hover": {
                      background: currentPath?.path === path ? theme.palette.background.default : "transparent",
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: 1,
                    },
                  })}
                  LinkComponent={Link}
                  to={path}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {/* <Divider /> */}
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Divider />
        </Box>
      </Drawer>
      <Main sx={{ py: 2, px: { xs: 4, sm: 8 } }} open={open} isMobile={isMobile}>
        <DrawerHeader sx={{ mb: 4 }} />
        <Typography variant="subtitle1">{currentPath?.label}</Typography>
        {children}
      </Main>
    </Box>
  );
}

export default Wrapper;
