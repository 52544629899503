import React, { FC, useState } from "react";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { alpha, Box, Button, CircularProgress, Divider, IconButton, Paper, TextField, Typography } from "@mui/material";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useHasPermission } from "../../hooks";
import { UserPermissions } from "../../utils/permissions";
import { publishedStatus } from "../../utils/constants";
import { IQuote, SubmitButtonTextEnum, SubmitButtonEnum, SubmitButtonType, TabKeyEnum } from "../../utils/types";
import { CreateQuoteParams, EditQuoteParams } from "../../api/api.types";
import { useDetectAnyChange, useDisableButtons } from "../../hooks";

interface Props {
  onSubmit: (data: CreateQuoteParams | EditQuoteParams) => void;
  loading: boolean;
  initialValues?: IQuote | null;
  handleClose: () => void;
  activeTab: TabKeyEnum;
  title?: string;
}

type QuoteValues = {
  quote: string;
  originalText: string;
  originalLang: any;
  originalCulture: any;
  source: any;
  author: any;
  originalTimePeriod: string;
  tags: any;
  reference: any;
};

const validateQuote = (data: any): { field: string; message: string }[] => {
  const requiredFields = [
    {
      field: "engText",
      message: "Quote is required",
    },
  ];

  const output = requiredFields.filter(({ field }) => !data[field]);
  return output;
};

const mapInitialValuesToQuoteValues = (initialValues?: IQuote | null): QuoteValues => {
  if (!initialValues) {
    return {
      quote: "",
      originalText: "",
      originalLang: "",
      originalCulture: "",
      source: "",
      author: "",
      originalTimePeriod: "",
      tags: [],
      reference: "",
    };
  }

  const cultures = initialValues.cultures?.map(cul => ({ id: cul?.id ?? "", label: cul?.culture ?? "" }));
  const sources = initialValues.sources?.map(cul => ({ id: cul?.id ?? "", label: cul?.name ?? "" }));
  const authors = initialValues.authors?.map(cul => ({ id: cul?.id ?? "", label: cul?.name ?? "" }));
  const tags = initialValues.tags?.map(cul => ({ id: cul?.id ?? "", label: cul?.tag ?? "" })) ?? [];
  const refs = initialValues.refs?.map(ref => ({ id: ref?.id ?? "", label: ref?.text ?? "" })) ?? [];

  // TODO: Refactor originalLang to be an array or tied to the langugae table in the backend like other select properties instead of a string.
  // Currently, the data is returned as a string, not an array with id and name.
  // Additionally, it is also sent back as a string, not an array of ids.
  // This inconsistency should be addressed to maintain uniformity in data handling.
  const langs =
    typeof initialValues.origLang === "string" && initialValues.origLang // Making sure is a string or an array to avoid breaking the code incase the  data structure changes.
      ? [{ id: initialValues.origLang, label: initialValues.origLang }]
      : Array.isArray(initialValues.origLang)
        ? initialValues.origLang?.map(lang => ({ id: lang, label: lang }))
        : [];

  return {
    quote: initialValues.engText || "",
    originalText: initialValues.origText || "",
    originalLang: langs?.[0] || "",
    originalCulture: cultures?.[0] || "",
    source: sources?.[0] || "",
    author: authors?.[0] || "",
    originalTimePeriod: initialValues.origTimePeriod || "",
    tags,
    reference: refs?.[0] || "",
  };
};

export const CreateOrEditQuote: FC<Props> = props => {
  const { loading, onSubmit, initialValues, title, activeTab } = props;

  const [errors, setErrors] = useState<{ field: string; message: string }[]>([]);
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  const [originalQuoteValues] = useState<QuoteValues>(mapInitialValuesToQuoteValues(initialValues));
  const [quoteValues, setQuoteValues] = useState<QuoteValues>(mapInitialValuesToQuoteValues(initialValues));

  const handleQuoteOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuoteValues({ ...quoteValues, quote: event.target.value });

    if (event.target.value) {
      setErrors(errors.filter(({ field }) => field !== "engText"));
      return;
    }
    setErrors([...errors, { field: "engText", message: "Quote is required" }]);
  };

  const canCreateQuotes = useHasPermission(UserPermissions.CREATE_QUOTES);
  const canEditReadyAndUnPublishedQuotes = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES);

  const showSaveButtons = initialValues ? canEditReadyAndUnPublishedQuotes : canCreateQuotes;

  const handleSubmit = (published: number, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);

    const { quote, originalText, originalLang, originalCulture, source, author, originalTimePeriod, tags, reference } = quoteValues;
    const requestBody: CreateQuoteParams | EditQuoteParams = {
      engText: quote.trim(),
      origText: originalText.trim(),
      origLang: [originalLang?.id || originalLang].filter(Boolean)[0] || "",
      cultures: [originalCulture?.id || originalCulture].filter(Boolean),
      sources: [source?.id || source].filter(Boolean),
      authors: [author?.id || author].filter(Boolean),
      origTimePeriod: originalTimePeriod.trim(),
      tags: tags.map(({ id }: { id: string | number; label: string }) => id) ?? tags,
      id: initialValues?.id || undefined,
      published,
      refs: [reference?.id || reference].filter(Boolean),
    };

    const validation = validateQuote(requestBody);
    setErrors(validation);
    if (!validation.length) {
      onSubmit(requestBody);
    }
  };

  const hasChanges = useDetectAnyChange<Partial<any>>({ initialObject: originalQuoteValues, object: quoteValues });
  const isEditing = initialValues !== null;
  const hasRequiredFields = !!quoteValues.quote.trim();

  const { disableReadyForReviewButton, disableSaveAsUnpublishedButton } = useDisableButtons({
    isLoading: loading,
    hasRequiredFields,
    isEditing,
    hasChanges: hasChanges(),
    activeTab,
  });

  return (
    <Paper
      sx={theme => ({
        background: alpha(theme.palette.background.default, 1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: 500,
        maxWidth: 500,
        overflowY: "auto",
      })}
    >
      <Box display="flex" justify-content="space-between" alignItems="center" sx={{ padding: "24px 24px 0", width: "100%" }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          {title}
        </Typography>
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <Box sx={{ p: 3, flex: 1 }}>
          <TextField
            error={!!errors.find(({ field }) => field === "engText")}
            helperText={errors.find(({ field }) => field === "engText")?.message}
            label="Quote"
            value={quoteValues.quote}
            onChange={handleQuoteOnChange}
            onBlur={handleQuoteOnChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Original Language Text"
            value={quoteValues.originalText}
            onChange={e => setQuoteValues({ ...quoteValues, originalText: e.target.value })}
            multiline
            fullWidth
            error={!!errors.find(({ field }) => field === "originalText")}
            helperText={errors.find(({ field }) => field === "originalText")?.message}
            margin="normal"
          />
          <CustomSelect
            onChange={(_, newValue) => {
              setQuoteValues({ ...quoteValues, originalLang: newValue });
              setErrors(errors.filter(({ field }) => field !== "origLang"));
            }}
            value={quoteValues.originalLang}
            processResponse={res => {
              // Ensure we don't have duplicates to prevent filtering/search from breaking
              return _.uniqBy(
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.lang,
                    label: item.lang,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? [],
                (item: any) => item.id,
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "origLang"),
              helperText: errors.find(({ field }) => field === "origLang")?.message,
              label: "Original Language",
            }}
            url={"/api/langs?published=1"}
            getQueryParams={(name: string) => ({ lang: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setQuoteValues({ ...quoteValues, originalCulture: newValue })}
            value={quoteValues.originalCulture}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.culture,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "cultures"),
              helperText: errors.find(({ field }) => field === "cultures")?.message,
              label: "Original Culture",
            }}
            url={"/api/cultures?published=1"}
            getQueryParams={(culture: string) => ({ culture: culture.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue: any, reason) => {
              if (newValue?._original?.authors?.length) {
                setQuoteValues({
                  ...quoteValues,
                  source: newValue,
                  author: {
                    id: newValue._original?.authors?.[0]?.id,
                    label: newValue._original?.authors?.[0]?.name,
                  },
                });
                return;
              }
              setQuoteValues({ ...quoteValues, source: newValue });
            }}
            value={quoteValues.source}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.name,
                    _original: item,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "source"),
              helperText: errors.find(({ field }) => field === "source")?.message,
              label: "Source",
            }}
            url={"/api/books?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setQuoteValues({ ...quoteValues, author: newValue })}
            value={quoteValues.author}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.name,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "author"),
              helperText: errors.find(({ field }) => field === "author")?.message,
              label: "Authors",
            }}
            url={"/api/authors?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
          <TextField
            variant="standard"
            label="Original Time Period"
            value={quoteValues.originalTimePeriod}
            onChange={e => setQuoteValues({ ...quoteValues, originalTimePeriod: e.target.value })}
            fullWidth
            margin="normal"
          />
          <CustomSelect
            onChange={(_, newValue) => setQuoteValues({ ...quoteValues, tags: newValue })}
            multiple
            filterSelectedOptions
            value={quoteValues.tags}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.tag,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "tags"),
              helperText: errors.find(({ field }) => field === "tags")?.message,
              label: "Tags",
            }}
            url={"/api/tags?published=1"}
            getQueryParams={(name: string) => ({ tag: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setQuoteValues({ ...quoteValues, reference: newValue })}
            value={quoteValues.reference}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.text,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "refs"),
              helperText: errors.find(({ field }) => field === "refs")?.message,
              label: "Reference",
              multiline: true,
            }}
            url={"/api/refs?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" sx={{ py: 3, px: 2, position: "relative", bottom: 0, gap: 1 }}>
          {showSaveButtons && (
            <Button
              onClick={() => handleSubmit(publishedStatus.readyToReview, SubmitButtonEnum.ReadyForReview)}
              disabled={disableReadyForReviewButton}
              sx={{ borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              {loading && clickedButton === SubmitButtonEnum.ReadyForReview ? <CircularProgress size={16} /> : ""}
              {SubmitButtonTextEnum.ReadyForReview}
            </Button>
          )}
          {showSaveButtons && (
            <Button
              onClick={() => handleSubmit(publishedStatus.unpublished, SubmitButtonEnum.SaveAsUnpublished)}
              disabled={disableSaveAsUnpublishedButton}
              disableElevation
              color="primary"
              sx={theme => ({
                borderRadius: "20px",
                backgroundColor: alpha(theme.palette.primary.main, 0.5),
              })}
              variant="contained"
              type="button"
            >
              {loading && clickedButton === SubmitButtonEnum.SaveAsUnpublished ? <CircularProgress size={16} /> : ""}
              {SubmitButtonTextEnum.SaveAsUnpublished}
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={props.handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
