import { Middleware } from "@reduxjs/toolkit";
import { api } from "../api/apiSlice";
import { ipApi } from "../api/thirdPartyApiSlice";

const customMiddlewares: Middleware[] = [api.middleware, ipApi.middleware];

const configureMiddlewares = (getDefaultMiddleware: () => Middleware[]) => {
  return getDefaultMiddleware().concat(customMiddlewares);
};

export default configureMiddlewares;
